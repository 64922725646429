export const TYPES = {
  SET_ORDERS: 'SET_ORDERS',
  FETCH_ORDERS: 'FETCH_ORDERS',
  SET_ORDERS_PAGE_PARAMAS: 'SET_ORDERS_PAGE_PARAMAS',
  SET_ORDERS_PAGE_FILTER_PARAMS: 'SET_ORDERS_PAGE_FILTER_PARAMS',
  SET_ORDERS_CREATE_PAGE_ENTITIES: 'SET_ORDERS_CREATE_PAGE_ENTITIES',
  CREATE_A_NEW_ORDER: 'CREATE_A_NEW_ORDER',
  UPDATE_ORDERS: 'UPDATE_ORDERS',
  SHOW_BOM_MODEL: 'SHOW_BOM_MODEL',
  HIDE_BOM_MODEL: 'HIDE_BOM_MODEL',
  CSV_DATA_SET: 'CSV_DATA_SET',
  SHOW_ENTITY_CREATE_MODAL: 'SHOW_ENTITY_CREATE_MODAL',
  STORE_NEW_ENTITY_CREATED: 'STORE_NEW_ENTITY_CREATED',
  HIDE_ENTITY_CREATE_MODAL: 'HIDE_ENTITY_CREATE_MODAL',
  FETCHING_PARENT_ORDERS: 'FETCHING_PARENT_ORDERS',
  SET_PARENT_ORDERS: 'SET_PARENT_ORDERS',
  SET_ORDER_DOWNLOAD_FORMATS: 'SET_ORDER_DOWNLOAD_FORMATS',
  OPEN_TRASH_MODAL: 'OPEN_TRASH_MODAL',
  CLOSE_TRASH_MODAL: 'CLOSE_TRASH_MODAL',
};

export const DEFAULT = {
  orders: [],
  pageParams: {
    page_size: 50,
    page_no: 0,
  },
  filterParams: {
    from_date: null,
    to_date: null,
    pre_defined_time: '',
  },
  entities: [],
  orders_details: {},
  show_add_bom_menu: false,
  show_add_entity_menu: false,
  new_entity_created: null,
  selected_order_src_id: null,
  selected_order_src_type: null,
  parent_orders_list: [],
  order_download_formats: [],
  label_print_records: [],
  label_print_group_fields: [],
  label_print_group_fields_selected: [],
  show_label_print_modal: false,
  show_print_part_labels: false,
  show_trash_modal: false,
};
