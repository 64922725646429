import React, { Fragment, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { StatusGreenCompleted } from '../common/status-component';
import { CutGuideWoCardStat } from '../../views/cutguide/common';
import { useSelector } from 'react-redux';
import moment from 'moment';
import BottomDrawerInit from '../BottomDrawer/DrawerInit';
import useMobile from '../../hooks/useMobile';
const CompletedCard = ({ completedCardDetails, completedCardStat }) => {
  const [isMobile] = useMobile();

  const { org } = useSelector(state => state.user);
  const [bottomDrawerModal, setBottomDrawerModal] = useState(false);
  const ModalBody = useCallback(
    type => {
      if (bottomDrawerModal) {
        return (
          <div className='row col-lg-12 col-md-9 my-16'>
            <div className='col-12 flex-column rubik-font'>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  marginBottom: '15px',
                  fontWeight: 400,
                }}
              >
                Shipment {completedCardDetails?.code}
              </p>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  color: '#006eed',
                  fontWeight: 400,
                }}
                className='mt-5'
              >
                <div onClick={() => {}}>Share Report</div>
                <hr style={{ backgroundColor: 'black' }} />
                <div onClick={() => {}}>Download Report</div>
                <hr style={{ backgroundColor: 'black' }} />
                <div onClick={() => {}}>Reset Delivery Status</div>
                <hr style={{ backgroundColor: 'black' }} />
                <div onClick={() => {}}>Report an issue</div>
              </div>
            </div>
          </div>
        );
      }
    },
    [bottomDrawerModal, completedCardDetails?.code],
  );

  return (
    <Fragment>
      <div className='d-pod-card-container'>
        <div className=' px-16 py-16 rubik-font'>
          <div className='d-pod-card'>
            <div className='d-flex justify-content-between '>
              <span className='d-pod-card-id'>
                {completedCardDetails?.code}
              </span>
              <span>
                <div
                  onClick={() => {
                    setBottomDrawerModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    style={{ color: '#0066cc' }}
                  />
                </div>
              </span>
            </div>
            <div
              style={{ width: 'fit-content' }}
              className='naadi-status ps-1 pe-1 mt-3'
            >
              <StatusGreenCompleted />
              <div>DELIVERED</div>
            </div>

            <div className='d-pod-card-body'>
              <div className=' location-container d-flex justify-content-between py-16'>
                <span className='location me-2'>
                  <span>Location:</span>{' '}
                  <a href='#address'>
                    {completedCardDetails?.delivery_address}
                  </a>
                </span>
              </div>

              <div className='d-pod-card-info'>
                <CutGuideWoCardStat
                  statCount={completedCardStat?.stat?.box_processed || 0}
                  totalCount={completedCardStat?.stat?.box_total || 0}
                  label={'BOXES'}
                />
                <div className='d-flex flex-column justify-content-end'>
                  <div className='vendor-name'>{org.name}</div>
                  <div className='date-time'>
                    {moment(completedCardDetails?.updated_on).format(
                      "Do MMM 'YY, hh:mma",
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomDrawerInit
        entity={'showMenuModal'}
        isMobile={isMobile}
        data={ModalBody}
        menuModal={bottomDrawerModal}
        setMenuModal={setBottomDrawerModal}
      />
    </Fragment>
  );
};

const CompletedPodList = ({
  dPodList,
  setEntityModal,
  setBottomDrawerModal,
}) => {
  return (
    <div className='d-pod-card-list-container'>
      {dPodList &&
        dPodList.map((list, index) => {
          const completedList = list.shipments?.[`${list.ctx_id}`];
          return (
            <div key={index}>
              <CompletedCard
                completedCardDetails={completedList}
                completedCardStat={list}
                setBottomDrawerModal={setBottomDrawerModal}
                setEntityModal={setEntityModal}
              />
            </div>
          );
        })}
    </div>
  );
};

export default CompletedPodList;
