import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy } from 'react-table';
import BTable from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { ChevronDownIcon } from '../listColumnFilter/icon';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPageFilterParams,
  formatFiltersObj,
  fetchCustomers,
  FETCHING_CUSTOMERS,
} from '../../../store/order/ordersList/actions';
import { useHistory } from 'react-router-dom';
import { cloneDeep, union } from 'lodash';
import Form from 'react-bootstrap/Form';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const filterableParams = {
  term: {
    name: 'Search',
    key: 'term',
    type: 'string',
  },
  code_term: {
    name: 'Order Code',
    key: 'code_term',
    type: 'array',
  },
  ref_code_term: {
    name: 'Reference Code',
    key: 'ref_code_term',
    type: 'array',
  },
  ext_code_term: {
    name: 'Customer Code',
    key: 'ext_code_term',
    type: 'array',
  },
  parent_order_id_term: {
    name: 'Parent Order',
    key: 'parent_order_id_term',
    type: 'array',
  },
  entity_id: {
    name: 'Customer Name',
    key: 'entity_id',
    type: 'entity',
  },
  // from_date: {
  //   name: 'from_date',
  //   key: 'from_date',
  //   type: 'date',
  // },
  // to_date: {
  //   name: 'to_date',
  //   key: 'to_date',
  //   type: 'date',
  // },
  // delivery_from_date: {
  //   name: 'delivery_from_date',
  //   key: 'delivery_to_date',
  //   type: 'date',
  // },
};

const modalFilterableParams = {
  trash_modal_term: {
    name: 'Search',
    key: 'trash_modal_term',
    type: 'string',
  },
  trash_modal_code_term: {
    name: 'Order Code',
    key: 'trash_modal_code_term',
    type: 'string',
  },
  trash_modal_ref_code_term: {
    name: 'Reference Code',
    key: 'trash_modal_ref_code_term',
    type: 'string',
  },
  trash_modal_ext_code_term: {
    name: 'Customer Code',
    key: 'trash_modal_ext_code_term',
    type: 'string',
  },
  trash_modal_parent_order_id_term: {
    name: 'Parent Order',
    key: 'trash_modal_parent_order_id_term',
    type: 'string',
  },
  trash_modal_entity_id: {
    name: 'Customer Name',
    key: 'trash_modal_entity_id',
    type: 'entity',
  },
};

const StatusEnum = {
  CREATED: {
    key: 'CREATED',
    text: 'Created',
  },
  CONFIRMED: {
    key: 'CONFIRMED',
    text: 'Confirmed',
  },
  PRODUCTION: {
    key: 'PRODUCTION',
    text: 'Production',
  },
  DISPATCHED: {
    key: 'DISPATCHED',
    text: 'Dispatched',
  },
  BILLED: {
    key: 'BILLED',
    text: 'Billed',
  },
  CLOSED: {
    key: 'CLOSED',
    text: 'Closed',
  },
};

function Table({ columns, data, has_filters, onSortChange, columnSort }) {
  // eslint-disable-next-line no-unused-vars
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          // eslint-disable-next-line security/detect-object-injection
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: (columnSort || []).map(val => {
          return {
            id: val.column,
            desc: val.asc !== true,
          };
        }),
      },
    },
    useFilters,
    useSortBy,
  );
  useEffect(() => {
    onSortChange(sortBy);
  }, [sortBy, onSortChange]);
  return (
    <div className='tableFixHead'>
      <BTable {...getTableProps()} className='table' responsive>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    ...(column.width
                      ? {
                          width: `${column.width}px`,
                        }
                      : {}),
                    ...(column.minWidth ? { minWidth: column.minWidth } : {}),
                  }}
                  title=''
                >
                  <div
                    className={`pb-2 table-header${
                      column.isSorted ? ' active' : ''
                    }${column.align_right ? ' text-right' : ''}`}
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <span className='ms-2 px-2 float-right'>
                        <FontAwesomeIcon
                          icon={column.isSortedDesc ? faCaretDown : faCaretUp}
                        />
                      </span>
                    )}
                  </div>
                  {has_filters && (
                    <div className='me-2 pt-2'>
                      {column.canFilter && column.Filter ? (
                        column.render('Filter')
                      ) : (
                        <div />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};

export default Table;

const ColumnStringFilter = ({ column }) => {
  const history = useHistory();
  const { filter_key } = column;
  const filter_params = useSelector(
    state => state.ordersList.filterParams || {},
  );
  const dispatch = useDispatch();
  const input = useRef(null);
  const handleSubmit = useCallback(
    (event, selected_column) => {
      event.preventDefault();
      const data = new FormData(event.target);
      const form_values = data.get(selected_column);
      const clone_filter_params = cloneDeep(filter_params);
      const type = filterableParams[`${selected_column}`].type;
      if (type === 'string') {
        dispatch(
          setPageFilterParams(
            formatFiltersObj({
              ...clone_filter_params,
              [selected_column]: form_values,
            }),
          ),
        );
        const stringified_params = qs.stringify(
          formatFiltersObj({
            ...clone_filter_params,
            [selected_column]: form_values,
          }),
        );
        history.push(`/app/orders/list?${stringified_params}`);
      }
      if (type === 'array') {
        let changed_array = [];
        if (
          clone_filter_params[`${selected_column}`] &&
          clone_filter_params[`${selected_column}`].length
        ) {
          changed_array = clone_filter_params[`${selected_column}`];
        }
        const new_filters = {
          ...clone_filter_params,
          [selected_column]: union(changed_array, [form_values]),
        };
        dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
        const stringified_params = qs.stringify(formatFiltersObj(new_filters));
        history.push(`/app/orders/list?${stringified_params}`);
      }
      if (input) {
        input.current.value = null;
      }
    },
    [dispatch, filter_params, history],
  );

  return (
    <Form onSubmit={event => handleSubmit(event, filter_key)}>
      <input
        type='search'
        className='table-search-input'
        placeholder={'Search'}
        onClick={e => e.stopPropagation()}
        ref={input}
        name={filter_key}
      />
    </Form>
  );
};

const DefaultColumnFilter = ColumnStringFilter;

export function StatusFilter({ column: { filterValue, filter_key } }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const filter_params =
    useSelector(state => state.ordersList.filterParams) || {};
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer`}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const handleStatusSelect = data => {
    if (data) {
      const clone_filter_params = cloneDeep(filter_params);
      let changed_array = [];
      if (
        clone_filter_params[`${filter_key}`] &&
        clone_filter_params[`${filter_key}`].length
      ) {
        changed_array = clone_filter_params[`${filter_key}`];
      }
      const new_filters = {
        ...clone_filter_params,
        [filter_key]: union(changed_array, [data]),
      };
      dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
      const stringified_params = qs.stringify(formatFiltersObj(new_filters));
      history.push(`/app/orders/list?${stringified_params}`);
    }
  };

  return (
    <div style={{ marginTop: '-6px' }}>
      <Dropdown className='page-size-selector dropdown-table-filter'>
        <Dropdown.Toggle as={CustomToggle}>
          <div className='d-flex justify-content-between'>
            <span></span>
            <ChevronDownIcon />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ overflow: 'scroll', height: '250px' }}>
          {Object.values(StatusEnum).map(filter => (
            <Dropdown.Item
              onClick={() => handleStatusSelect(filter.key)}
              active={filter.key === filterValue}
              key={filter.key}
            >
              {filter.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const StatusColumnFilter = StatusFilter;

export function CustomerFilterComponent({
  //eslint-disable-line
  column: { filter_key },
  history,
}) {
  const dispatch = useDispatch();
  const busy = useSelector(state => state.busy);
  const is_fetching_entities = busy.includes(FETCHING_CUSTOMERS);
  const entities = useSelector(state => state.ordersList.entities);
  const filter_params =
    useSelector(state => state.ordersList.filterParams) || {};

  const handleEntitySearch = term => {
    dispatch(fetchCustomers(dispatch, { term, deleted: [false] }));
  };

  const handleEntitySelect = data => {
    if (data) {
      const clone_filter_params = cloneDeep(filter_params);
      let changed_array = [];
      if (
        clone_filter_params[`${filter_key}`] &&
        clone_filter_params[`${filter_key}`].length
      ) {
        changed_array = clone_filter_params[`${filter_key}`];
      }
      const new_filters = {
        ...clone_filter_params,
        [filter_key]: union(changed_array, [data.uuid]),
      };
      dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
      const stringified_params = qs.stringify(formatFiltersObj(new_filters));
      history.push(`/app/orders/list?${stringified_params}`);
    }
  };
  const preventClickDefault = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div onClick={preventClickDefault} style={{ marginTop: '-10px' }}>
      <AsyncTypeahead
        id='select_parent_order_typeahead'
        labelKey='name'
        onSearch={handleEntitySearch}
        autoComplete='off'
        onChange={selected_entity => handleEntitySelect(selected_entity[0])}
        options={entities}
        isLoading={is_fetching_entities}
      />
    </div>
  );
}
const ModalColumnStringFilter = ({ column }) => {
  const history = useHistory();
  const { filter_key } = column;
  const filter_params = useSelector(
    state => state.ordersList.filterParams || {},
  );
  const dispatch = useDispatch();
  const input = useRef(null);
  const handleSubmit = useCallback(
    (event, selected_column) => {
      event.preventDefault();
      const data = new FormData(event.target);
      const form_values = data.get(selected_column);
      const clone_filter_params = cloneDeep(filter_params);
      const type = modalFilterableParams[`${selected_column}`].type;
      if (type === 'string') {
        dispatch(
          setPageFilterParams(
            formatFiltersObj({
              ...clone_filter_params,
              [selected_column]: form_values,
            }),
          ),
        );
        const stringified_params = qs.stringify(
          formatFiltersObj({
            ...clone_filter_params,
            [selected_column]: form_values,
          }),
        );
        history.push(`/app/orders/list?${stringified_params}`);
      }
      if (type === 'array') {
        let changed_array = [];
        if (
          clone_filter_params[`${selected_column}`] &&
          clone_filter_params[`${selected_column}`].length
        ) {
          changed_array = clone_filter_params[`${selected_column}`];
        }
        const new_filters = {
          ...clone_filter_params,
          [selected_column]: union(changed_array, [form_values]),
        };
        dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
        // eslint-disable-next-line no-unused-vars
        const stringified_params = qs.stringify(formatFiltersObj(new_filters));
        // history.push(`/app/orders/list?${stringified_params}`);
      }
      if (input) {
        input.current.value = null;
      }
    },
    [dispatch, filter_params, history],
  );

  return (
    <Form onSubmit={event => handleSubmit(event, filter_key)}>
      <input
        type='search'
        className='table-search-input'
        placeholder={'Search'}
        onClick={e => e.stopPropagation()}
        ref={input}
        name={filter_key}
      />
    </Form>
  );
};

const ModalColumnFilter = ModalColumnStringFilter;
const CustomerFilter = CustomerFilterComponent;
export {
  DefaultColumnFilter,
  StatusColumnFilter,
  CustomerFilter,
  ModalColumnFilter,
};
