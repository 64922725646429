import React from 'react';
import { IconInfoCircled, IconInfoCircledWhite } from './iconSetting';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

function FormCutsmartSetting({
  optimization_level,
  onChangeOptimization_level,
  waste_min,
  onChangeWaste_min,
  book_cut,
  onCheckboxBook_cut,
  minimize_sheet_rotation,
  onCheckboxMinimize_sheet_rotation,
  disabled,
}) {
  return (
    <div>
      <div className=' d-flex '>
        <Form.Group className='form-group cs-box-width1'>
          <FormLabel className='cs-opt-label'>LEVEL OF OPTIMIZATION</FormLabel>
          <Form.Select
            // defaultValue='Select a Value'
            className='cs-select-num'
            defaultValue={optimization_level}
            onChange={onChangeOptimization_level}
            disabled={disabled}
          >
            <option value=''>Select a Value</option>
            {[2, 3, 4, 5, 6].map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <span className='cs-info-icon'>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={props => (
              <Tooltip {...props}>
                <span className='cs-tooltips'>
                  <IconInfoCircledWhite className='cs-tooltipColor' />
                  OPTIMIZATION LEVEL
                  <article className='cs-textS'>
                    Ranges from 2-6. Higher level leads to more complicated
                    patterns and lesser Wastage
                  </article>
                </span>
              </Tooltip>
            )}
            placement='bottom'
          >
            <button disabled className='cs-dis-btn'>
              <IconInfoCircled />
            </button>
          </OverlayTrigger>
        </span>
      </div>
      <div className=' d-flex '>
        <Form.Group className='form-group cs-box-width1'>
          <FormLabel className='opt-label'>MINIMUM OFFCUT DIMENSIONS</FormLabel>
          <Form.Control
            type='text'
            disabled={disabled}
            placeholder='Enter Minimum Offcut Dimensions'
            autoComplete='off'
            className='cs-form-controls'
            value={waste_min}
            onChange={onChangeWaste_min}
          ></Form.Control>
        </Form.Group>
      </div>
      <div className='d-flex my-2'>
        <input
          disabled={disabled}
          type='checkbox'
          className=' cursor-pointer'
          checked={book_cut === true}
          value={book_cut}
          onChange={onCheckboxBook_cut}
        />
        <Form.Check.Label className='mt-1 ps-1 cs-fonts'>
          Book Cuts
        </Form.Check.Label>
        <span className='cs-icons'>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={props => (
              <Tooltip {...props}>
                <span className='cs-tooltips'>
                  <IconInfoCircledWhite className='cs-tooltipColor' />
                  Book Cuts
                  <article className='cs-textS'>Enables Book Cuts</article>
                </span>
              </Tooltip>
            )}
            placement='bottom'
          >
            <button disabled className='cs-dis-btn'>
              <IconInfoCircled />
            </button>
          </OverlayTrigger>
        </span>
      </div>
      <div className='d-flex'>
        <input
          disabled={disabled}
          type='checkbox'
          className='cursor-pointer'
          checked={minimize_sheet_rotation === true}
          value={minimize_sheet_rotation}
          onChange={onCheckboxMinimize_sheet_rotation}
        />
        <Form.Check.Label className='mt-1 ps-1 cs-fonts'>
          Minimize Rotation
        </Form.Check.Label>
        <span className='cs-icons'>
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={props => (
              <Tooltip {...props}>
                <span className='cs-tooltips'>
                  <IconInfoCircledWhite className='cs-tooltipColor' />
                  Minimize Rotation
                  <article className='cs-textS'>
                    MATERIAL OPTIMIZATION Minimizes the rotation of Panels
                    during cutting
                  </article>
                </span>
              </Tooltip>
            )}
            placement='bottom'
          >
            <button disabled className='cs-dis-btn'>
              <IconInfoCircled />
            </button>
          </OverlayTrigger>
        </span>
      </div>
    </div>
  );
}

export default FormCutsmartSetting;
