export const TYPES = {
  SELECT_SHIPMENT: 'SELECT_SHIPMENT',
  SET_STAT: 'SET_STAT',
  SET_SHIPMENTS: 'SET_SHIPMENTS',
  SET_SHIPMENT_FILTERS: 'SET_SHIPMENT_FILTERS',
  ADD_STATUS_FILTER: 'ADD_STATUS_FILTER',
  SET_ORDERS: 'SET_ORDERS',
  RESET_SHIPMENT: 'RESET_SHIPMENT',
  ON_CHANGE_BOX_STATUS: 'ON_CHANGE_BOX_STATUS',
  ON_RESET_BOX_STATUS: 'ON_RESET_BOX_STATUS',
  SET_ORDERS_FILTER: 'SET_ORDERS_FILTER',
  SET_BOX_QUANTITY: 'SET_BOX_QUANTITY',
  ON_UPDATED_BOX_STATUS: 'ON_UPDATED_BOX_STATUS',
  SET_ITEM_DETAIL: 'SET_ITEM_DETAIL',
  SET_SHIPMENT_SUMMARY_TEMPLATE: 'SET_SHIPMENT_SUMMARY_TEMPLATE',
  SET_SHIPMENT_ORDER_SUMMARY_TEMPLATE: 'SET_SHIPMENT_ORDER_SUMMARY_TEMPLATE',
};

export const DEFAULT = {
  category: {
    label: 'Shipment',
    value: 'SHIPMENT',
  },
  shipments: [],
  stat: {
    CLOSED: 0,
    OPEN: 0,
  },
  shipments_filters: {
    SHIPMENT_STATUS: [
      {
        label: 'Yet to Start',
        value: 'NOT_LOADING',
      },
      {
        label: 'Loading',
        value: 'LOADING',
      },
    ],
  },
  shipment: null,
  orders: null,
  orders_filters: {},
  item_detail: null,
  shipment_summary_template: null,
  shipment_order_summary_template: null,
};
