import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import QrReader from 'react-qr-scanner';
import { toastError } from '../../../helpers/packing/packingHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCameraRotate,
  faCircleXmark,
  faEllipsisVertical,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import {
  handelOnlineScan,
  toastScanMode,
  updateOrdersDetails,
  updatePodState,
  toastSuccess,
  verifyRejectScan,
  onUndoHandler,
} from '../../../helpers/ext-digitalpod/digitalPodHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentScanItem,
  setDPodExternalState,
  setRejectReasonModal,
  setRejectMode,
  updatePendSyncStateItems,
  setLastScanItem,
} from '../../../store/digitalpod/dpodexternstationstate';
import OrderCard from '../../../components/external-digital-pod/order-pod-card';
import { Modal } from 'react-bootstrap';
import ProgressSection from '../../../components/external-digital-pod/progress-section';
import TrackTraceRejectToggle from '../../../components/tracktrace/reject-toggle';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

const DigitalPodQrScanner = ({ showModal, onCloseCb }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isOnline = navigator.onLine;
  const [defaultCamera, setDefaultCamera] = useState(1);
  const [cameras, setCameras] = useState([]);
  const mediaRequested = useRef(false);
  const [isTorchOn, setIsTorchOn] = useState(false);
  const lastScan = useRef({ text: '', scanTime: 0 });
  const [secureHost, setSecureHost] = useState(false);
  const [scannerMenuModal, setScannerMenuModal] = useState(false);
  const [enterManuallyModal, setEnterManuallyModal] = useState(false);
  const [updatedScanState, setUpdatedScanState] = useState({});

  const { reject_mode, dPodExternalState, lastScanItem } = useSelector(
    state => {
      return state.dpodexternstationstate;
    },
  );
  const [isScanning, setIsScanning] = useState(true);
  const toggleCamera = useCallback(() => {
    setDefaultCamera(prev => prev + 1);
  }, []);
  const [enterSerialCode, setEnterSerialCode] = useState('');
  useEffect(() => {
    const handleLightLevel = event => {
      const lightLevel = event.value;
      if (lightLevel < 10) {
        setIsTorchOn(true);
      } else {
        setIsTorchOn(false);
      }
    };

    if ('ondevicelight' in window) {
      window.addEventListener('devicelight', handleLightLevel);
    } else {
      console.warn(
        'Ambient Light Sensor not supported. Falling back to manual control.',
      );
    }

    return () => {
      window.removeEventListener('devicelight', handleLightLevel);
    };
  }, []);
  useEffect(() => {
    dispatch(setCurrentScanItem({}));
    const initializeCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          device => device.kind === 'videoinput',
        );
        setCameras(videoDevices);
      } catch (err) {
        console.error('Failed to access cameras:', err);
      }
    };

    initializeCameras();

    if (
      window.location.protocol === 'https:' ||
      ['localhost', '127.0.0.1'].includes(window.location.hostname)
    ) {
      setSecureHost(true);
    } else {
      toastError('Use the https protocol to access the camera.');
    }
  }, [dispatch]);

  const onScanComplete = useCallback(
    async text => {
      const { updatedState, matchFound, currentDpod, currentItem, undoItem } =
        updatePodState(dPodExternalState, text);
      if (matchFound) {
        const { updatedState: fullyUpdatedState, updatedOrder } =
          updateOrdersDetails(updatedState, currentDpod, text);
        currentDpod.ordersDetails = updatedOrder;
        setUpdatedScanState(currentDpod);
        dispatch(setLastScanItem(undoItem));
        if (isOnline) {
          const res = await handelOnlineScan(currentDpod, currentItem);
          if (res.status !== 200) {
            return;
          }
        } else {
          dispatch(updatePendSyncStateItems(currentDpod, currentItem));
        }
        dispatch(setDPodExternalState(fullyUpdatedState));
        toastSuccess(`${text} delivered`, () =>
          onUndoHandler(
            fullyUpdatedState,
            currentDpod,
            undoItem,
            setUpdatedScanState,
            isOnline,
            dispatch,
          ),
        );
      } else {
        return null;
      }
    },
    [dPodExternalState, dispatch, isOnline],
  );

  const onScanReject = useCallback(
    async text => {
      const { matchFound, currentDpod, currentItem } = verifyRejectScan(
        dPodExternalState,
        text,
      );
      if (matchFound) {
        setIsScanning(false);
        dispatch(setCurrentScanItem(currentItem));
        dispatch(setRejectReasonModal(true));
        if (id) {
          onCloseCb();
        } else {
          onCloseCb();
          history.push(`/app/dpodexternal/details/${currentDpod.dPod_id}`);
        }
      } else {
        return null;
      }
    },
    [dPodExternalState, dispatch, history, id, onCloseCb],
  );
  const handleScan = useCallback(
    data => {
      if (
        lastScan.current.scanTime > 0 &&
        new Date().getTime() - lastScan.current.scanTime > 10000
      ) {
        lastScan.current.scanTime = 0;
        lastScan.current.text = '';
      }

      if (data && data.text && data.text !== lastScan.current.text) {
        const text = data.text.trim();
        lastScan.current.text = text;
        lastScan.current.scanTime = new Date().getTime();

        if (text !== null) {
          reject_mode === false ? onScanComplete(text) : onScanReject(text);
        }
      }
    },
    [reject_mode, onScanComplete, onScanReject],
  );

  const handleError = err => {
    if (err.message === 'OverconstrainedError' && !mediaRequested.current) {
      mediaRequested.current = true;
      navigator.getUserMedia(
        { video: true },
        () => {
          toastError(
            'Camera enabled. If the image is not displayed, refresh the page.',
          );
        },
        error => console.error('Camera access error:', error),
      );
    }
    console.error('QR Scanner Error:', err);
  };
  const ModalBody = useCallback(
    type => {
      return (
        <div className='row col-lg-12 col-md-9 my-16'>
          <div className='col-12 flex-column rubik-font'>
            <p
              style={{
                textAlign: 'center',
                fontSize: '20px',
                marginBottom: '15px',
                fontWeight: 400,
              }}
            >
              Options
            </p>
            <div
              style={{
                textAlign: 'center',
                fontSize: '16px',
                color: '#006eed',
                fontWeight: 400,
              }}
              className='mt-5'
            >
              <div
                className='text-danger'
                onClick={() => {
                  dispatch(setRejectMode(!reject_mode));
                  setScannerMenuModal(false);
                  toastScanMode(
                    !reject_mode
                      ? 'Switched to REJECT Mode'
                      : 'Switched to SCAN Mode',
                  );
                }}
              >
                Switch to {!reject_mode ? 'Reject Mode' : 'Scan Mode'}
              </div>
              <hr style={{ backgroundColor: 'black' }} />
              <div
                onClick={() => {
                  const currentDPod = dPodExternalState.find(d =>
                    d.result.some(
                      result =>
                        result?.box?.code === lastScanItem.code ||
                        result?.order_item?.code === lastScanItem.code,
                    ),
                  );
                  onUndoHandler(
                    dPodExternalState,
                    currentDPod,
                    lastScanItem,
                    setUpdatedScanState,
                    isOnline,
                    dispatch,
                  );
                }}
              >
                Click Here Undo Last Scan
              </div>
              <hr style={{ backgroundColor: 'black' }} />
              <div
                onClick={() => {
                  setEnterManuallyModal(true);
                }}
              >
                Enter Code Manually
              </div>
              <hr style={{ backgroundColor: 'black' }} />
              <div onClick={() => {}}>Need Help</div>
            </div>
          </div>
        </div>
      );
    },
    [dPodExternalState, dispatch, isOnline, lastScanItem, reject_mode],
  );

  const enterManuallyModalBody = useCallback(
    type => {
      return (
        <div className='row col-lg-12 col-md-9 my-16 fontRubik'>
          <div>
            <p
              style={{
                fontSize: '24px',
                marginBottom: '15px',
                fontWeight: 600,
              }}
            >
              {'Enter Barcode Number'}
            </p>
            <div className='my-4'>
              You can enter the barcode Number manually
            </div>
            <div className='mb-3'>
              <div className='py-2 px-3 rubik-font bg-light notes'>
                <span style={{ fontSize: '10px' }}>Barcode Number</span>
                <div className='d-flex justify-content-between'>
                  <input
                    type='text'
                    className='form-control border-0 input-custom py-1'
                    id='note'
                    placeholder='Enter barcode Number Here'
                    value={enterSerialCode}
                    onChange={e => setEnterSerialCode(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ color: '#32323266', paddingLeft: '10px' }}
                    onClick={() => setEnterSerialCode('')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>Includes boxes from all orders</div>

          <div
            className='d-flex justify-content-between w-100 pt-5 '
            style={{ gap: '1rem' }}
          >
            <button
              className='btn btn-outline-primary btn-lg'
              style={{
                background: 'white',
                width: '100%',
              }}
              onClick={() => {
                setEnterSerialCode('');
                setEnterManuallyModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-outline-primary btn-lg bg-primary text-white'
              style={{
                width: '100%',
              }}
              onClick={() => {
                setEnterManuallyModal(false);
                // submite()
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      );
    },
    [enterSerialCode],
  );
  return (
    <Fragment>
      <Modal
        centered={true}
        show={showModal}
        onHide={onCloseCb}
        fullscreen={true}
      >
        <Modal.Body className='p-0 m-0'>
          <div
            className='qr-scanner-container'
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {secureHost && (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 20px',
                    zIndex: 10,
                    color: '#027AFF',
                  }}
                  className='my-16'
                >
                  <div className='d-flex gap-3'>
                    <div
                      className={`d-flex justify-content-center align-items-center ${
                        isTorchOn ? 'bg-primary' : 'bg-white'
                      } rounded-circle`}
                      style={{ width: '40px', height: '40px' }}
                      onClick={() => setIsTorchOn(!isTorchOn)}
                    >
                      <FontAwesomeIcon
                        icon={faBolt}
                        color={isTorchOn ? 'white' : '027AFF'}
                      />{' '}
                    </div>
                    <div
                      className='d-flex justify-content-center align-items-center bg-white rounded-circle'
                      style={{ width: '40px', height: '40px' }}
                    >
                      <FontAwesomeIcon
                        icon={faCameraRotate}
                        onClick={toggleCamera}
                      />
                    </div>
                  </div>
                  <div
                    className='d-flex justify-content-center align-items-center bg-white rounded-circle text-dark'
                    style={{ width: '40px', height: '40px' }}
                    onClick={() => onCloseCb()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '35%',
                    left: '50%',
                    bottom: '5%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    height: '25vh',
                  }}
                >
                  {!reject_mode ? (
                    <div
                      style={{
                        position: 'relative',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white',
                      }}
                      className='naadi-text d-flex justify-content-center gap-1 mb-3'
                    >
                      <span>Scan for</span>
                      <span style={{ color: '#17FF00' }}>Delivery</span>
                    </div>
                  ) : (
                    <div
                      style={{
                        position: 'relative',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white',
                      }}
                      className='naadi-text d-flex justify-content-center gap-1 mb-3'
                    >
                      <span style={{ color: 'white' }}> Scan for Damages</span>
                    </div>
                  )}
                  <div
                    className={`d-pod-scanner-frame ${
                      reject_mode && 'reject-mode'
                    }`}
                  >
                    <div>
                      {isScanning && (
                        <span
                          className='tt-qr-scanner-line'
                          style={{
                            backgroundColor: !reject_mode
                              ? '#17FF00'
                              : '#C00000',
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
                <QrReader
                  key={Date.now()}
                  constraints={{
                    video: {
                      facingMode: 'environment',
                      torch: isTorchOn,
                      deviceId: cameras.length
                        ? cameras[defaultCamera % cameras.length].deviceId
                        : undefined,
                    },
                  }}
                  style={{
                    width: '100%',
                    height: '60vh',
                    objectFit: 'cover',
                    // backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  }}
                  onError={handleError}
                  onScan={data => {
                    if (isScanning) {
                      handleScan(data);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Modal.Body>

        <div className='bottom-modal-container'>
          <div className='d-flex align-items-center justify-content-between pb-3'>
            <div>
              <span
                style={{
                  fontSize: '20px',
                  color: isOnline ? '#008A50' : '#C00000',
                  paddingRight: '5px',
                }}
              >
                ●
              </span>
              <span className='naadi-text'>
                {isOnline ? 'ONLINE' : 'OFFLINE'}
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='pe-2'>Reject Mode</div>
              <TrackTraceRejectToggle
                rejectmode={reject_mode}
                onRejectModeToggle={() => dispatch(setRejectMode(!reject_mode))}
                hideLable={true}
              />{' '}
            </div>
          </div>

          <div className='w-100 p-0 d-inline-block '>
            <div className=' w-100  pb-2'>
              <ProgressSection
                totalProcessed={updatedScanState?.stat?.total_processed || 0}
                totalRejected={updatedScanState?.stat?.total_rejected || 0}
                totalItems={updatedScanState?.stat?.total || 0}
                label='Total Progress:'
              />
            </div>

            <div className='pt-4'>
              {' '}
              <span className='naadi-text fontRoboto'>Last Scanned:</span>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '130px' }}
              >
                {updatedScanState &&
                Object.keys(updatedScanState).length === 0 ? (
                  <div
                    className='naadi-text  text-center'
                    style={{
                      color: '#32323266',
                    }}
                  >
                    <div>Nothing to show here.</div>
                    <div>Start by scanning a QR</div>
                  </div>
                ) : (
                  <OrderCard
                    order={updatedScanState?.ordersDetails}
                    scanned={true}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 13,
              right: 13,
              width: '40px',
              height: '40px',
            }}
            className='d-flex justify-content-center align-items-center rounded-circle bg-primary ext-dpod-shadow  '
            onClick={() => {
              setScannerMenuModal(true);
              setIsScanning(false);
              // setEntityModal('optionsMenuModal');
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{ color: 'white' }}
            />
          </div>
        </div>
        {scannerMenuModal && (
          <Modal
            show={scannerMenuModal}
            onHide={() => {
              setScannerMenuModal(false);
              setIsScanning(true);
            }}
            dialogClassName='m-0'
          >
            <div className='bottom-modal-container'>{ModalBody()}</div>
          </Modal>
        )}
        {enterManuallyModalBody && (
          <Modal
            show={enterManuallyModal}
            onHide={() => {
              setEnterManuallyModal(false);
              setIsScanning(true);
            }}
            dialogClassName='m-0'
          >
            <div className='bottom-modal-container'>
              {enterManuallyModalBody()}
            </div>
          </Modal>
        )}
        {(scannerMenuModal || enterManuallyModal) && (
          <span className='background-overlay p-0 m-0'></span>
        )}
        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          className=' custom-toast-container-dpod naadi-text fs-2'
        />
      </Modal>
    </Fragment>
  );
};

export default DigitalPodQrScanner;
