import React from 'react';
import { Drawer } from 'antd';

const BottomDrawer = ({
  drawerTitle,
  handleClose,
  popUp,
  entity,
  drawerBody,
}) => {
  return (
    <Drawer
      title={
        entity === 'imageModal' ? (
          <span style={{ fontSize: '24px', paddingTop: '24px' }}>
            {' '}
            {drawerTitle}{' '}
          </span>
        ) : (
          drawerTitle
        )
      }
      styles={{
        wrapper: {
          height: 'auto',
        },
      }}
      placement={'bottom'}
      closable={false}
      onClose={handleClose}
      visible={popUp}
      key={'bottom'}
      style={{
        borderRadius: '16px 16px 0px 0px',
        height: 'fit-content',
        position: 'fixed',
        bottom: 0,
      }}
      className={`bottomDrawer ${entity}`}
    >
      {drawerBody}
    </Drawer>
  );
};

export default BottomDrawer;
