import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import ScanIcon from '../../../assets/icons/scan-icon.svg';
import { OrgLogo } from '../../../components/org/org-logo';
import { setRejectMode } from '../../../store/digitalpod/dpodexternstationstate';
import { useDispatch, useSelector } from 'react-redux';
import DigitalPodQrScanner from './qrcodeScaner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const Footer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showQrModal, setShowQrModal] = useState(false);
  const { reject_mode } = useSelector(state => {
    return state.dpodexternstationstate;
  });
  return (
    <Fragment>
      <div
        // className='custom-card-kl'
        style={{
          position: 'fixed',
          bottom: 0,
          height: '72px',
          boxShadow: '-4px -4px 4px #0000001A',
          background: 'white',
          width: '100%',
        }}
      >
        <div className='scan-wrapper'>
          <div
            onClick={() => {
              setShowQrModal(true);
              if (reject_mode) {
                dispatch(setRejectMode(false));
              }
            }}
            className='scan-icon'
          >
            <img
              src={ScanIcon}
              alt='Scan here to ship boxes'
              className='cursor-pointer align-items-center'
              style={{
                filter: 'invert(100%) brightness(100%)',
              }}
            />{' '}
          </div>
        </div>
        <div className='ext-dpod-footer rubik-font'>
          <div
            onClick={() => history.push('/app/dpodexternal')}
            className='footer-item'
          >
            <FontAwesomeIcon
              icon={faHouse}
              style={{ color: '#006EED', width: '24px', height: '24px' }}
            />
            <span>Home</span>
          </div>
          <div className='footer-item'>
            <div style={{ width: '24px', height: '24px' }}></div>
            <span>Scan QR</span>
          </div>
          <div className='footer-item'>
            <div
              onClick={() => history.push('/app/dpod/profile')}
              style={{ width: '24px', height: '24px' }}
            >
              <OrgLogo />
            </div>
            <span>Akhil</span>
          </div>
        </div>
      </div>
      {showQrModal && (
        <DigitalPodQrScanner
          showModal={showQrModal}
          onCloseCb={() => {
            setShowQrModal(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default Footer;
