import React from 'react';

export const BackIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} className='cursor-pointer'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'
      >
        <path
          fill='#000'
          d='M15 19l1.41-1.41L11.83 13l-1-.964 1-1.036 4.59-4.59L15 5l-7 7 7 7z'
        ></path>
      </svg>
    </div>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        d='M23.446 13.282v-2.564H13.282V.554h-2.564v10.164H.554v2.564h10.164v10.164h2.564V13.282h10.164z'
      ></path>
    </svg>
  );
};
export const StorageIcon = ({ iconColor }) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='40'
        height='40'
        rx='20'
        fill={iconColor}
        fillOpacity='0.04'
      />
      <path
        d='M30.0423 13.0846C30.4173 13.4596 30.6673 14.0013 30.6673 14.5013V23.668C30.6673 25.168 29.459 26.3346 28.0007 26.3346H16.0007C14.5007 26.3346 13.334 25.168 13.334 23.668V11.668C13.334 10.2096 14.5007 9.0013 16.0007 9.0013H25.1673C25.6673 9.0013 26.209 9.2513 26.584 9.6263L30.0423 13.0846ZM18.6673 11.0013V13.0013H23.334V11.0013H18.6673ZM28.6673 23.668V14.6263C28.6673 14.543 28.6257 14.5013 28.584 14.4596L25.334 11.168V14.0013C25.334 14.5846 24.8757 15.0013 24.334 15.0013H17.6673C17.084 15.0013 16.6673 14.5846 16.6673 14.0013V11.0013H16.0007C15.6257 11.0013 15.334 11.3346 15.334 11.668V23.668C15.334 24.043 15.6257 24.3346 16.0007 24.3346H28.0007C28.334 24.3346 28.6673 24.043 28.6673 23.668ZM22.0007 17.0013C23.459 17.0013 24.6673 18.2096 24.6673 19.668C24.6673 21.168 23.459 22.3346 22.0007 22.3346C20.5007 22.3346 19.334 21.168 19.334 19.668C19.334 18.2096 20.5007 17.0013 22.0007 17.0013ZM25.6673 30.3346C26.209 30.3346 26.6673 29.918 26.6673 29.3346C26.6673 28.793 26.209 28.3346 25.6673 28.3346H14.334C12.6673 28.3346 11.334 27.0013 11.334 25.3346V14.0013C11.334 13.4596 10.8757 13.0013 10.334 13.0013C9.75065 13.0013 9.33398 13.4596 9.33398 14.0013V25.3346C9.33398 28.1263 11.5423 30.3346 14.334 30.3346H25.6673Z'
        fill={iconColor}
      />
    </svg>
  );
};
export const FilterIcon = () => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.07031 0.789062C1.23438 0.460938 1.5625 0.25 1.9375 0.25H12.0625C12.4141 0.25 12.7422 0.460938 12.9062 0.789062C13.0469 1.11719 13 1.51562 12.7656 1.79688L8.5 7.02344V10C8.5 10.3047 8.33594 10.5625 8.07812 10.6797C7.82031 10.7969 7.51562 10.7734 7.28125 10.6094L5.78125 9.48438C5.59375 9.34375 5.5 9.13281 5.5 8.875V7.02344L1.21094 1.79688C0.976562 1.51562 0.929688 1.11719 1.07031 0.789062Z'
        fill='#006EED'
      />
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.75 5.375C9.75 6.45312 9.39844 7.46094 8.8125 8.25781L11.7656 11.2344C12.0703 11.5156 12.0703 12.0078 11.7656 12.2891C11.4844 12.5938 10.9922 12.5938 10.7109 12.2891L7.73438 9.3125C6.9375 9.92188 5.92969 10.25 4.875 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.17969 0.5 4.875 0.5C7.54688 0.5 9.75 2.70312 9.75 5.375ZM4.875 8.75C6.72656 8.75 8.25 7.25 8.25 5.375C8.25 3.52344 6.72656 2 4.875 2C3 2 1.5 3.52344 1.5 5.375C1.5 7.25 3 8.75 4.875 8.75Z'
        fill='#323232'
        fillOpacity='0.4'
      />
    </svg>
  );
};

export const TickSuccessIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.5 16C4.0625 16 0.5 12.4375 0.5 8C0.5 3.59375 4.0625 0 8.5 0C12.9062 0 16.5 3.59375 16.5 8C16.5 12.4375 12.9062 16 8.5 16ZM12.0312 6.53125H12C12.3125 6.25 12.3125 5.78125 12 5.46875C11.7188 5.1875 11.25 5.1875 10.9688 5.46875L7.5 8.96875L6.03125 7.5C5.71875 7.1875 5.25 7.1875 4.96875 7.5C4.65625 7.78125 4.65625 8.25 4.96875 8.53125L6.96875 10.5312C7.25 10.8438 7.71875 10.8438 8.03125 10.5312L12.0312 6.53125Z'
        fill='#008A50'
      />
    </svg>
  );
};
