import { DEFAULT } from './const';
import Update from 'immutability-helper';
import { createReducer } from '@reduxjs/toolkit';
import {
  setOrders,
  setPageParams,
  setPageFilterParams,
  setEntities,
  updateOrders,
  showBomMenu,
  hideBomMenu,
  setCSVData,
  showEntityCreateModal,
  storeNewOrderEntityCreated,
  hideEntityCreateModal,
  setOrderSrcType,
  setOrderSrcId,
  setParentOrders,
  setOrderDownloadFormats,
  showPrinterPartLabelUploader,
  setLabelPrintGroupFields,
  setLabelPrintGroupFieldsSelected,
  setLabelPrintRecords,
  showLabelPrintModal,
  showTrashModal,
  closeTrashModal,
} from './actions';

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(setOrders, (state, { payload }) => {
    state.orders = payload || [];
  });
  builder.addCase(setPageParams, (state, { payload }) => {
    return Update(state, {
      pageParams: { $merge: payload },
    });
  });
  builder.addCase(setPageFilterParams, (state, { payload }) => {
    return Update(state, {
      filterParams: { $merge: payload },
    });
  });
  builder.addCase(setEntities, (state, { payload }) => {
    return Update(state, {
      entities: { $set: payload },
    });
  });
  builder.addCase(updateOrders, (state, { payload }) => {
    return Update(state, {
      orders_details: { $merge: payload },
    });
  });
  builder.addCase(showBomMenu, state => {
    return Update(state, {
      show_add_bom_menu: { $set: true },
    });
  });
  builder.addCase(hideBomMenu, state => {
    return Update(state, {
      show_add_bom_menu: { $set: false },
    });
  });
  builder.addCase(setOrderSrcType, (state, { payload }) => {
    return Update(state, {
      selected_order_src_type: { $set: payload.type },
    });
  });
  builder.addCase(setOrderSrcId, (state, { payload }) => {
    return Update(state, {
      selected_order_src_id: { $set: payload.order_src_id },
    });
  });
  builder.addCase(setCSVData, (state, { payload }) => {
    return Update(state, {
      csv_list: { $set: payload.list },
      csv_columns: { $set: payload.columns },
      file_name: { $set: payload.file_name },
      column_headers: { $set: payload.column_headers },
    });
  });
  builder.addCase(showEntityCreateModal, state => {
    return Update(state, {
      show_add_entity_menu: { $set: true },
      new_entity_created: { $set: null },
    });
  });
  builder.addCase(storeNewOrderEntityCreated, (state, { payload }) => {
    return Update(state, {
      new_entity_created: { $set: payload },
    });
  });
  builder.addCase(hideEntityCreateModal, state => {
    return Update(state, {
      show_add_entity_menu: { $set: false },
    });
  });
  builder.addCase(setParentOrders, (state, { payload }) => {
    return Update(state, {
      parent_orders_list: { $set: payload },
    });
  });
  builder.addCase(setOrderDownloadFormats, (state, { payload }) => {
    return Update(state, {
      order_download_formats: { $set: payload },
    });
  });
  builder.addCase(showPrinterPartLabelUploader, (state, { payload }) => {
    return Update(state, {
      show_print_part_labels: { $set: payload === true },
    });
  });
  builder.addCase(showLabelPrintModal, (state, { payload }) => {
    return Update(state, {
      show_label_print_modal: { $set: payload === true },
    });
  });
  builder.addCase(setLabelPrintRecords, (state, { payload }) => {
    return Update(state, {
      label_print_records: { $set: payload },
    });
  });
  builder.addCase(setLabelPrintGroupFields, (state, { payload }) => {
    return Update(state, {
      label_print_group_fields: { $set: payload },
    });
  });
  builder.addCase(setLabelPrintGroupFieldsSelected, (state, { payload }) => {
    return Update(state, {
      label_print_group_fields_selected: { $set: payload },
    });
  });
  builder.addCase(showTrashModal, (state, { payload }) => {
    return Update(state, {
      show_trash_modal: { $set: true },
    });
  });
  builder.addCase(closeTrashModal, (state, { payload }) => {
    return Update(state, {
      show_trash_modal: { $set: false },
    });
  });
});

export default reducer;
