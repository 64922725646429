import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import HeaderODelete, {
  PaginationComponent,
  TrashBanner,
} from './header-o-delete';
import { StorageBar } from './header-o-delete';
import { FilterIcon, SearchIcon } from '../icon';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  TableLink,
  TableDateCell,
} from '../../../components/order/common/tableCells';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import { getOrders, inactiveOrder, orgDataSize } from '../../../services/order';
import { ChipSelect } from '../../report/time-period';
import Form from 'react-bootstrap/Form';
import { actions } from '@naadi/framework';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import Table from '../../../components/order/common/table';
import BlackClose from '../../../assets/icons/black-close.svg';
import {
  FETCHING_CUSTOMERS,
  fetchCustomers,
} from '../../../store/order/ordersList/actions';
import { toastError } from '../../../helpers/packing/packingHelper';
import { getOrderProgress } from '../../../services/order-operation-stat';
const statuss = [
  { value: 'CREATED', label: 'CREATED' },
  { value: 'RELEASED', label: 'Released' },
  { value: 'ONHOLD', label: 'On Hold' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'PRODUCTION', label: 'PRODUCTION' },
  { value: 'CONFIRMED', label: 'CONFIRMED' },
];
const styles = {
  padding: '6px 8px',
  background: '#027AFF1A',
  borderRadius: '8px',
  cursor: 'pointer',
};

const PARENTORDER = 'PARENT ORDER';
const CUSTOMERREFERENCE = 'CUSTOMER REFERENCE';
const SECONDARYCUSTOMER = 'SECONDARY CUSTOMER';
const ORDERCREATIONDATE = 'ORDER CREATION DATE';
const ORDERSTATUS = 'ORDER STATUS';
function OrderDeleteHome() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [modelDropDown, setModelDropdown] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [deletedOrders, setDeletedOrders] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  console.log('isDeleteMode', isDeleteMode);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [status, setStatus] = useState([]);
  const [tempValue, setTempValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [showAppliedFiltersModal, setShowAppliedFiltersModal] = useState(false);
  const [showTrashBanner, setShowTrashBanner] = useState(false);
  const [tempDates, setTempDates] = useState({
    from_date: '',
    to_date: '',
  });
  const [order, setOrder] = useState({
    parentOrder: [],
    customerReference: [],
    secondaryCustomer: [],
    order: '',
    entity_id: [],
    name: '',
  });
  const [progressData, setProgressData] = useState([]);
  const [dataSize, setDataSize] = useState(null);
  const [selectedDateOption, setSelectedDateOption] = useState('Before');
  const [totalCount, setTotalCount] = useState(0);
  const [storedTotalCount, setStoredTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const pageSize = 50;
  const [filterParams, setFilterParams] = useState({
    // from_date: '',
    // to_date: '',
    term: '',
    code: [],
    code_term: [],
    ext_code_term: [],
    entity_id: [],
    fetch_branch_detail: true,
    fetch_count: true,
    fetch_entities: true,
    notuuid: [],
    parent_order_id_term: [],
    ref_code: [],
    ref_code_term: [],
    secondary_customer_term: [],
    status: [],
    delivery_date: '',
    name: [],
  });
  const [allOrders, setAllOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const busy = useSelector(state => state.busy);
  const isFetchingEntities = busy.includes(FETCHING_CUSTOMERS);
  const entities = useSelector(state => state.ordersList.entities);
  const handleSearch = useCallback(
    term => {
      dispatch(fetchCustomers(dispatch, { term, deleted: [false] }));
    },
    [dispatch],
  );
  const handleCancelDelete = useCallback(() => {
    setIsDeleteMode(false);
    setSelectedOrders([]);
    setShowConfirmDelete(false);
  }, []);
  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(allOrders);
    }
    setSelectAll(prevSelectAll => !prevSelectAll);
  }, [selectAll, allOrders]);
  const handleSelectOrder = useCallback(
    order => {
      const isSelected = selectedOrders.includes(order);
      if (isSelected) {
        setSelectedOrders(prevSelectedOrders =>
          prevSelectedOrders.filter(selectedOrder => selectedOrder !== order),
        );
      } else {
        setSelectedOrders(prevSelectedOrders => [...prevSelectedOrders, order]);
      }
    },
    [selectedOrders],
  );
  const filterOptions = [
    'ORDER',
    PARENTORDER,
    CUSTOMERREFERENCE,
    'CUSTOMER',
    SECONDARYCUSTOMER,
    ORDERCREATIONDATE,
    ORDERSTATUS,
  ];
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedFilter(null);
  }, []);
  const handleFilterClick = useCallback(option => {
    setSelectedFilter(option);
    setShowDropdown(false);
    setModelDropdown(false);
    setShowAppliedFiltersModal(false);
    setShowModal(true);
    setTempValue(null);
  }, []);
  const handleDateOptionChange = useCallback(option => {
    setSelectedDateOption(option);
    if (option === 'Before') {
      setTempDates({
        from_date: null,
        to_date: null,
      });
    } else if (option === 'After') {
      setTempDates({
        from_date: null,
        to_date: new Date().toISOString(),
      });
    } else if (option === 'Between') {
      setTempDates({});
    }
  }, []);

  const handleTempDateChange = useCallback(
    (field, day) => {
      const isoString = day ? day.toISOString() : '';
      setTempDates(prev => {
        const updatedDates = { ...prev, [field]: isoString };
        if (selectedDateOption === 'Before' && field === 'to_date') {
          if (updatedDates.to_date) {
            const oneYearEarlier = new Date(updatedDates.to_date);
            oneYearEarlier.setFullYear(oneYearEarlier.getFullYear() - 1);
            updatedDates.from_date = oneYearEarlier.toISOString();
          } else {
            updatedDates.from_date = null;
          }
        }
        return updatedDates;
      });
    },
    [selectedDateOption],
  );

  const filteredOptions = filterOptions.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const handleFilterToggle = useCallback(
    isOpen => {
      if (appliedFilters.length > 0) {
        setShowDropdown(false);
        setShowAppliedFiltersModal(true);
      } else {
        setShowDropdown(isOpen);
      }
    },
    [appliedFilters.length],
  );
  useEffect(() => {
    const fetchOrgDataSize = async () => {
      try {
        const response = await orgDataSize();
        setDataSize(response.payload);
      } catch (error) {
        toastError.error('Error fetching organization data size');
      }
    };
    fetchOrgDataSize();
  }, []);
  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await getOrderProgress(
          'PARENT_ORDER',
          false,
          false,
          '',
          '',
          false,
          'ALL',
          500,
        );
        const payload = response.payload;
        setProgressData(payload);
      } catch (error) {
        toastError('Failed to fetch progress data:', error);
      }
    };
    fetchProgressData();
  }, []);
  const applyFilter = useCallback(
    async (page = pageNo) => {
      try {
        const page_params = {
          page_no: page,
          page_size: pageSize,
        };
        const allOrders = await getOrders(filterParams, page_params);
        const payload = allOrders.payload;
        const total = allOrders.total;
        if (page === 0) {
          setStoredTotalCount(total);
          setTotalCount(total);
        } else {
          setTotalCount(storedTotalCount);
        }
        setAllOrders(payload);
        setShowAppliedFiltersModal(false);
        setModelDropdown(false);
      } catch (error) {
        console.log('Error fetching orders:', error.message);
      }
    },
    [filterParams, pageNo, storedTotalCount],
  );
  const handlePageChange = useCallback(
    async newPageNo => {
      setPageNo(newPageNo);
      await applyFilter(newPageNo);
    },
    [applyFilter],
  );
  const confirmDelete = useCallback(async () => {
    try {
      dispatch(actions.busy.add('ORDER_DELETE'));
      setIsDeleteMode(true);
      for (const order of selectedOrders) {
        await inactiveOrder(order.uuid);
      }
      setShowConfirmDelete(false);
      applyFilter();
      setSelectedOrders([]);
      setDeletedOrders(selectedOrders);
      setShowTrashBanner(true);
      setTimeout(() => setShowTrashBanner(false), 5000);
    } catch (error) {
      setIsDeleteMode(false);
      toastError('order delete failed');
    } finally {
      dispatch(actions.busy.remove('ORDER_DELETE'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedOrders]);
  const columns = [
    {
      Header: () => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            onChange={() => handleSelectAll()}
            checked={selectAll}
            style={{ marginRight: '5px' }}
          />
          <span className='ml-2'>Order</span> {/* Column title */}
        </div>
      ),
      accessor: 'ref_code',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <input
                type='checkbox'
                onChange={() => handleSelectOrder(row.original)}
                checked={selectedOrders.includes(row.original)}
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </div>
            <TableLink
              text={values.ref_code}
              url={`/app/orders/details/${original.uuid}`}
              is_bold
            />
          </div>
        ); // eslint-disable-line
      },
      width: 100,
      filter_key: 'term',
    },
    {
      Header: 'Parent Order',
      accessor: 'parent_order',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        if (values.parent_order.uuid) {
          // eslint-disable-line
          return (
            <TableLink
              text={values.parent_order.ref_code}
              url={`/app/orders/details/${values.parent_order.uuid}`}
            />
          ); // eslint-disable-line
        }
        return <div></div>;
      },
      filter_key: 'parent_order_id_term',
    },
    {
      Header: 'Customer Reference',
      accessor: 'ext_code',
      minWidth: '155px',
      filter_key: 'ext_code_term',
    },
    {
      Header: 'Customer',
      accessor: 'customer_name',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <TableLink
            text={values.customer_name}
            url={`/app/customers/${original.entity.uuid}`}
          />
        ); // eslint-disable-line
      },
      filter_key: 'customer_name',
    },
    {
      Header: 'Secondary Customer',
      accessor: 'secondary_customer',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.secondary_customer) {
          return <div></div>;
        }
        return <div>{original.secondary_customer}</div>;
      },
      filter_key: 'secondary_customer_term',
    },
    {
      Header: 'Branch',
      accessor: 'branch_id',
      filter_key: 'branch_id',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.branch_id || !original.branch) {
          return <div></div>;
        }
        return <div>{original.branch.name}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      filter_key: 'status',
    },
    {
      Header: 'Order Date',
      accessor: 'created_on',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return <TableDateCell value={values.created_on} />;
      },
    },
    {
      Header: 'Planned Shipment Date',
      accessor: 'delivery_date',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return values.delivery_date ? (
          <TableDateCell value={values.delivery_date} />
        ) : (
          ''
        );
      },
    },
    {
      Header: 'Completion %',
      accessor: 'completion_percentage',
      disableFilters: true,
      Cell: ({ row }) => {
        const order = row.original;
        const matchingProgress = progressData.find(
          progress => progress.key === order.uuid,
        );
        return matchingProgress ? `${matchingProgress.completion}%` : '';
      },
    },
  ];
  const moveToTrashText =
    selectedOrders.length === 1
      ? `Move ${selectedOrders.length} order to Trash`
      : `Move ${selectedOrders.length} orders to Trash`;

  const onCustomerSelect = useCallback(data => {
    const uuid = data.uuid;
    const name = data.name;
    setOrder(prevOrder => ({
      ...prevOrder,
      entity_id: uuid,
      name: name,
    }));
  }, []);
  useEffect(() => {
    if (appliedFilters.length === 1) {
      applyFilter();
    }
  }, [appliedFilters, applyFilter]);
  useEffect(() => {
    if (showModal && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showModal]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderModalContent = useCallback(() => {
    switch (selectedFilter) {
      case 'ORDER':
        return (
          <div>
            <Form.Group className={`form-group mb-1 cs-box-width`}>
              <FormLabel className='cs-opt-label'>ORDER ID</FormLabel>
              <Form.Control
                ref={inputRef}
                type='text'
                placeholder='Enter order name'
                autoComplete='off'
                value={tempValue}
                className='cs-form-controls'
                onChange={e => setTempValue(e.target.value)}
              />
            </Form.Group>
          </div>
        );
      case PARENTORDER:
        return (
          <div>
            <Form.Group className={`form-group mb-1 cs-box-width`}>
              <FormLabel className='cs-opt-label'>PARENT ORDER</FormLabel>
              <Form.Control
                ref={inputRef}
                type='text'
                placeholder='Enter parent order '
                autoComplete='off'
                value={order.parentOrder}
                className='cs-form-controls'
                onChange={e =>
                  setOrder({ ...order, parentOrder: e.target.value })
                }
              />
            </Form.Group>
          </div>
        );
      case CUSTOMERREFERENCE:
        return (
          <div>
            <Form.Group className={`form-group mb-1 cs-box-width`}>
              <FormLabel className='cs-opt-label'>CUSTOMER REFERENCE</FormLabel>
              <Form.Control
                ref={inputRef}
                type='text'
                placeholder='Enter parent order '
                autoComplete='off'
                value={order.customerReference}
                className='cs-form-controls'
                onChange={e =>
                  setOrder({ ...order, customerReference: e.target.value })
                }
              />
            </Form.Group>
          </div>
        );
      case 'CUSTOMER':
        return (
          <div>
            <Form.Group className='form-group mb-1 cs-box-width'>
              <FormLabel className='cs-opt-label'>CUSTOMER</FormLabel>
              <AsyncTypeahead
                id='customer_search'
                labelKey='name'
                onSearch={handleSearch}
                onChange={data => {
                  if (data.length) {
                    onCustomerSelect(data[0]);
                  }
                }}
                options={entities} // Fetch customers from Redux
                placeholder='Enter customer name'
                isLoading={isFetchingEntities}
                autoComplete='off'
              />
            </Form.Group>
          </div>
        );
      case SECONDARYCUSTOMER:
        return (
          <div>
            <Form.Group className={`form-group mb-1 cs-box-width`}>
              <FormLabel className='cs-opt-label'>SECONDARY CUSTOMER</FormLabel>
              <Form.Control
                ref={inputRef}
                type='text'
                placeholder='Enter parent order '
                autoComplete='off'
                value={order.secondaryCustomer}
                className='cs-form-controls'
                onChange={e =>
                  setOrder({ ...order, secondaryCustomer: e.target.value })
                }
              />
            </Form.Group>
          </div>
        );
      case ORDERCREATIONDATE:
        return (
          <div>
            <div className='d-flex gap-3 ms-1'>
              <span
                style={
                  selectedDateOption === 'Before'
                    ? { ...styles, color: 'blue' }
                    : styles
                }
                onClick={() => handleDateOptionChange('Before')}
              >
                Before
              </span>
              <span
                style={
                  selectedDateOption === 'After'
                    ? { ...styles, color: 'blue' }
                    : styles
                }
                onClick={() => handleDateOptionChange('After')}
              >
                After
              </span>
              <span
                style={
                  selectedDateOption === 'Between'
                    ? { ...styles, color: 'blue' }
                    : styles
                }
                onClick={() => handleDateOptionChange('Between')}
              >
                Between
              </span>
            </div>
            <div>
              {selectedDateOption === 'Before' ? (
                <Form.Group className='form-group mt-2 mb-1 cs-box-width'>
                  <FormLabel className='cs-opt-label'>DATE</FormLabel>
                  <DayPickerInput
                    id='toDate'
                    name='toDate'
                    placeholder='Please select date'
                    format=''
                    value={
                      tempDates.to_date
                        ? new Date(tempDates.to_date)
                        : undefined
                    }
                    onDayChange={day => handleTempDateChange('to_date', day)}
                    inputProps={{
                      style: {
                        maxWidth: 'none',
                        width: '300px',
                        borderRadius: '4px',
                        background: 'none',
                      },
                      className: 'orgFormFields',
                    }}
                  />
                </Form.Group>
              ) : selectedDateOption === 'After' ? (
                <Form.Group className='form-group mt-2 mb-1 cs-box-width'>
                  <FormLabel className='cs-opt-label'>Date</FormLabel>
                  <DayPickerInput
                    id='toDate'
                    name='toDate'
                    placeholder='Please select date'
                    format=''
                    value={
                      tempDates.from_date
                        ? new Date(tempDates.from_date)
                        : undefined
                    }
                    onDayChange={day => handleTempDateChange('from_date', day)}
                    inputProps={{
                      style: {
                        maxWidth: 'none',
                        width: '300px',
                        borderRadius: '4px',
                        background: 'none',
                      },
                      className: 'orgFormFields',
                    }}
                  />
                </Form.Group>
              ) : (
                <div className='d-flex flex-column gap-3'>
                  <Form.Group className='form-group mb-1 cs-box-width'>
                    <FormLabel className='cs-opt-label'>From Date</FormLabel>
                    <DayPickerInput
                      id='fromDate'
                      name='fromDate'
                      placeholder='From date'
                      value={
                        tempDates.from_date
                          ? new Date(tempDates.from_date)
                          : undefined
                      }
                      onDayChange={day =>
                        handleTempDateChange('from_date', day)
                      }
                      inputProps={{
                        style: {
                          maxWidth: 'none',
                          width: '300px',
                          borderRadius: '4px',
                          background: 'none',
                        },
                        className: 'orgFormFields',
                      }}
                    />
                  </Form.Group>

                  <Form.Group className='form-group mb-1 cs-box-width'>
                    <FormLabel className='cs-opt-label'>To Date</FormLabel>
                    <DayPickerInput
                      id='toDate'
                      name='toDate'
                      placeholder='To date'
                      value={
                        tempDates.to_date
                          ? new Date(tempDates.to_date)
                          : undefined
                      }
                      onDayChange={day => handleTempDateChange('to_date', day)}
                      inputProps={{
                        style: {
                          maxWidth: 'none',
                          width: '300px',
                          borderRadius: '4px',
                          background: 'none',
                        },
                        className: 'orgFormFields',
                      }}
                    />
                  </Form.Group>
                </div>
              )}
            </div>
          </div>
        );
      case ORDERSTATUS:
        return (
          <div className='col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 d-flex my-2'>
            <Form.Group
              className='form-group mb-1 cs-box-width ms-4'
              style={{ height: '55px', borderRadius: '8px' }}
            >
              <Form.Label className='mb-0'>ORDER STATUS</Form.Label>
              <ChipSelect
                options={statuss}
                selectedItems={status}
                setSelectedItems={setStatus}
                placeholder='SELECT'
              />
            </Form.Group>
          </div>
        );
      default:
        return <p>Select a filter to see the details.</p>;
    }
  }, [
    entities,
    handleDateOptionChange,
    handleSearch,
    handleTempDateChange,
    isFetchingEntities,
    onCustomerSelect,
    order,
    selectedDateOption,
    selectedFilter,
    status,
    tempDates,
    tempValue,
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleApplyFilter = useCallback(() => {
    setFilterParams(prevParams => {
      let updatedParams = { ...prevParams };
      // const newFilters = [];

      switch (selectedFilter) {
        case ORDERSTATUS:
          const statusValues = Array.isArray(status)
            ? status.map(item => item.value)
            : [status.value];
          updatedParams.status = [...prevParams.status, ...statusValues];
          break;

        case PARENTORDER:
          updatedParams.parent_order_id_term = [
            ...prevParams.parent_order_id_term,
            order.parentOrder,
          ];
          break;
        case CUSTOMERREFERENCE:
          updatedParams.ext_code_term = [
            ...prevParams.ext_code_term,
            order.customerReference,
          ];
          break;
        case SECONDARYCUSTOMER:
          updatedParams.secondary_customer_term = [
            ...prevParams.secondary_customer_term,
            order.secondaryCustomer,
          ];
          break;

        case 'CUSTOMER':
          updatedParams.entity_id = [...prevParams.entity_id, order.entity_id];
          updatedParams.name = [...prevParams.name, order.name];
          break;

        case ORDERCREATIONDATE:
          if (!updatedParams.from_date) delete updatedParams.from_date;
          if (!updatedParams.to_date) delete updatedParams.to_date;
          const { from_date, to_date } = tempDates || {};
          if (selectedDateOption === 'Before') {
            updatedParams.to_date = to_date || prevParams.to_date;
            if (updatedParams.to_date) {
              const oneYearLater = new Date(updatedParams.to_date);
              oneYearLater.setFullYear(oneYearLater.getFullYear() - 1);
              updatedParams.from_date = oneYearLater.toISOString();
            } else {
              updatedParams.from_date = new Date().toISOString();
            }
          } else if (selectedDateOption === 'After') {
            updatedParams.from_date = from_date || prevParams.from_date;
            updatedParams.to_date = new Date().toISOString();
          } else if (selectedDateOption === 'Between') {
            updatedParams.from_date = from_date || prevParams.from_date;
            updatedParams.to_date = to_date || prevParams.to_date;
          }
          break;
        case 'ORDER':
          updatedParams.term = tempValue || prevParams.term;
          break;

        default:
          break;
      }
      setAppliedFilters(getAppliedFilters(updatedParams));
      return updatedParams;
    });
    setShowModal(false);
    setSelectedFilter(null);
    setShowAppliedFiltersModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, selectedDateOption, selectedFilter, status, tempDates, tempValue]);
  const getAppliedFilters = useCallback(params => {
    const applied = [];
    if (params.status.length > 0)
      applied.push({ key: ORDERSTATUS, value: params.status });
    if (params.parent_order_id_term.length > 0)
      applied.push({ key: PARENTORDER, value: params.parent_order_id_term });
    if (params.ext_code_term.length > 0)
      applied.push({ key: CUSTOMERREFERENCE, value: params.ext_code_term });
    if (params.secondary_customer_term.length > 0)
      applied.push({
        key: SECONDARYCUSTOMER,
        value: params.secondary_customer_term,
      });
    if (params.entity_id.length > 0)
      applied.push({
        key: 'CUSTOMER',
        value: { entity: params.entity_id, name: params.name },
      });
    if (params.from_date || params.to_date)
      applied.push({
        key: ORDERCREATIONDATE,
        value: { from: params.from_date, to: params.to_date },
      });
    if (params.code.length > 0)
      applied.push({ key: 'CODE', value: params.code });
    if (params.term) applied.push({ key: 'ORDER', value: params.term });

    return applied;
  }, []);
  const handleRemoveFilter = useCallback(
    index => {
      const updatedFilters = [...appliedFilters];
      const removedFilter = updatedFilters.splice(index, 1)[0];
      setAppliedFilters(updatedFilters);
      setFilterParams(prevParams => {
        let updatedParams = { ...prevParams };
        switch (removedFilter.key) {
          case ORDERSTATUS:
            updatedParams.status = updatedParams.status.filter(
              status => !removedFilter.value.includes(status),
            );
            break;
          case PARENTORDER:
            updatedParams.parent_order_id_term =
              updatedParams.parent_order_id_term.filter(
                item => !removedFilter.value.includes(item),
              );
            break;
          case CUSTOMERREFERENCE:
            updatedParams.ext_code_term = updatedParams.ext_code_term.filter(
              item => !removedFilter.value.includes(item),
            );
            break;
          case SECONDARYCUSTOMER:
            updatedParams.secondary_customer_term =
              updatedParams.secondary_customer_term.filter(
                item => !removedFilter.value.includes(item),
              );
            break;
          case 'CUSTOMER':
            updatedParams.entity_id = updatedParams.entity_id.filter(
              item => !removedFilter.value.entity.includes(item),
            );
            updatedParams.name = '';
            break;
          case ORDERCREATIONDATE:
            delete updatedParams.from_date;
            delete updatedParams.to_date;
            break;
          case 'ORDER':
            updatedParams.term = '';
            break;
          default:
            break;
        }

        return updatedParams;
      });
    },
    [appliedFilters],
  );
  return (
    <Fragment>
      <div className='overflow-hidden cs-body h-100 w-100'>
        <HeaderODelete selectedOrders={selectedOrders} />
        <div className='d-flex'>
          <div className='mt-3'>
            <StorageBar usedStorages={dataSize} totalStorage={5} />
          </div>
          <div className='ms-auto' style={{ marginRight: '30px' }}>
            <Dropdown
              className='page-size-selector'
              style={{ top: '25px' }}
              show={showDropdown}
              onToggle={handleFilterToggle}
              id='dropdown-custom-components'
            >
              <Dropdown.Toggle
                as='div'
                className='no-arrow'
                style={{
                  padding: '6px 8px',
                  background: '#027AFF1A',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ fontSize: '12px' }}>
                  Filter ({appliedFilters.length})
                </span>

                <FilterIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu align='end' style={{ maxWidth: '300px' }}>
                <div className='p-0'>
                  <div className='d-flex p-2'>
                    <span className='p-0'>
                      <SearchIcon />
                    </span>
                    <input
                      type='text'
                      placeholder='Type here to search'
                      className='form-control'
                      style={{ fontSize: '12px' }}
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map(option => (
                      <Dropdown.Item
                        key={option}
                        style={{ fontSize: '12px', cursor: 'pointer' }}
                        onClick={() => handleFilterClick(option)}
                      >
                        {option}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <div style={{ fontSize: '12px', color: '#888' }}>
                      No options found
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div>
          <PaginationComponent
            totalCount={totalCount}
            pageNo={pageNo}
            onPageChange={handlePageChange}
          />
        </div>
        <div style={{ marginTop: '50px' }}>
          <Container fluid className=' h-100'>
            <div className='mx-4 mt-4'>
              <Table
                columns={columns}
                data={allOrders}
                columnSort={''}
                onSortChange={_sortBy => {
                  // const updatedSortBy = _sortBy.map(val => {
                  //   return {
                  //     column: val.id,
                  //     asc: val.desc !== true,
                  //   };
                  // });
                }}
              />
            </div>
          </Container>
        </div>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton className='border-0 p-2 mx-2 ms-4'>
            <Modal.Title className=' fs-5 mt-4'>
              <p style={{ fontSize: '11px', marginBottom: '0px' }}>Filter by</p>
              {selectedFilter}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className='ms-4'
            style={{ height: '180px', paddingTop: '28px' }}
          >
            {renderModalContent()}
          </Modal.Body>
          <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
            <Button
              variant='outline-primary'
              onClick={() => setShowModal(false)}
              style={{ width: '46%' }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleApplyFilter}
              style={{ width: '46%' }}
            >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
        {selectedOrders.length > 0 && (
          <div
            className='position-fixed bottom-0 bg-white d-flex justify-content-between align-items-center'
            style={{
              borderTop: '1px solid #ddd',
              minHeight: '58px',
              padding: '10px 15px',
              width: '100vw',
              left: '50px',
              right: '0',
              maxWidth: 'calc(100% - 50px)',
            }}
          >
            <div className=''>Select orders to delete</div>
            <div className='d-flex gap-3 flex-wrap justify-content-end w-100'>
              <button
                onClick={handleCancelDelete}
                className='btn btn-outline-primary'
              >
                Cancel
              </button>
              <button
                onClick={() => setShowConfirmDelete(true)}
                className='btn btn-danger'
              >
                Move to Trash <FaTrash />
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal show={showConfirmDelete} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton className='border-0 P-2'></Modal.Header>
        <Modal.Body
          className='text-center'
          style={{ paddingTop: '4px', paddingBottom: '50px' }}
        >
          <FaExclamationCircle size={48} className='text-danger mb-3' />
          <h5 className='fw-bold'>{moveToTrashText}</h5>
          <p className='mb-0'>
            Are you sure you want to proceed? Orders in the trash are
            recoverable for <strong> 30 days</strong> after which they will be
            permanently deleted
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            onClick={handleCancelDelete}
            style={{ width: '46%' }}
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={confirmDelete}
            style={{ width: '46%' }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showAppliedFiltersModal && !showModal && (
        <Modal
          show={showAppliedFiltersModal}
          onHide={() => setShowAppliedFiltersModal(false)}
        >
          <Modal.Header closeButton style={{ borderBottom: '0px' }}>
            <Modal.Title>Applied Filter ({appliedFilters.length})</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: '30px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {appliedFilters.map((filter, index) => (
                <div
                  key={index}
                  className='filter-btns mb-1'
                  style={{
                    border: '1px solid #027aff',
                    backgroundColor: '#afd6ff',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '3px 8px',
                    borderRadius: '10px',
                    marginRight: '10px',
                    marginBottom: '5px',
                    boxSizing: 'border-box',
                    transition: 'all .3s linear',
                  }}
                >
                  <div style={{ marginRight: '3px' }}>{filter.key}</div>
                  <strong>:</strong>
                  {typeof filter.value === 'object' && filter.value !== null
                    ? Array.isArray(filter.value)
                      ? filter.value.join(', ')
                      : filter.value.name
                      ? filter.value.name.join(', ')
                      : filter.value.entity
                      ? filter.value.entity.join(', ')
                      : filter.value.from && filter.value.to
                      ? `${new Date(filter.value.from).toLocaleDateString(
                          'en-CA',
                        )} to ${new Date(filter.value.to).toLocaleDateString(
                          'en-CA',
                        )}`
                      : ''
                    : filter.value}
                  <div>
                    <img
                      src={BlackClose}
                      alt='Remove'
                      style={{ cursor: 'pointer', marginLeft: '2px' }}
                      onClick={() => handleRemoveFilter(index)}
                    />
                  </div>
                </div>
              ))}
              <span
                style={{
                  border: '1px solid #afd6ff',
                  backgroundColor: '#afd6ff',
                  padding: '3px 8px',
                  borderRadius: '7px',
                  fontSize: '12px',
                  color: '#027aff',
                  cursor: 'pointer',
                }}
                onClick={() => setModelDropdown(prev => !prev)}
              >
                Add +
              </span>
              {modelDropDown && (
                <Dropdown.Menu
                  align='end'
                  show={modelDropDown}
                  style={{
                    position: 'absolute',
                    top: '60%',
                    left: '10px',
                    maxWidth: '300px',
                    zIndex: 1050,
                  }}
                >
                  <div className='p-4'>
                    {filterOptions &&
                      filterOptions.map(option => (
                        <Dropdown.Item
                          key={option}
                          style={{ fontSize: '12px' }}
                          className='py-1 text-dark cursor-pointer'
                          onClick={() => handleFilterClick(option)}
                        >
                          {option}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: '0px' }}
            className='d-flex justify-content-end'
          >
            <Button
              variant='outline-primary'
              style={{ width: '46%' }}
              onClick={() => {
                setShowAppliedFiltersModal(false);
                setModelDropdown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              style={{ width: '46%' }}
              onClick={() => applyFilter()}
            >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <TrashBanner
        orderCount={deletedOrders}
        show={showTrashBanner}
        setShowModal={true}
      />
    </Fragment>
  );
}

export default OrderDeleteHome;
