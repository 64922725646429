import React, { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '../order/listColumnFilter/icon';

const Search = ({
  placeholder = 'Search',
  on = 'enter',
  onSearch,
  icon = true,
  iconStyle,
}) => {
  const [search, setSearch] = useState('');
  const [inputStyle, setInputStyle] = useState({});
  const _iconStyle = iconStyle
    ? iconStyle
    : { position: 'absolute', bottom: '10px' };
  useEffect(() => {
    if (icon) {
      setInputStyle({
        paddingLeft: '32px',
      });
    } else {
      setInputStyle({});
    }
  }, [icon, setInputStyle]);
  const searchText = useDebounce(search);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (on === 'enter') {
        onSearch(search);
      }
    },
    [on, onSearch, search],
  );

  useEffect(() => {
    if (on === 'key') {
      onSearch(searchText);
    }
  }, [on, searchText, onSearch]);

  return (
    <Fragment>
      <form
        onSubmit={onSubmit}
        className={`input-group `}
        style={inputStyle}
        noValidate
      >
        <input
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={placeholder}
          className='table-search-input form-control'
        />
      </form>
      {icon && (
        <span className='d-flex align-self-center' style={_iconStyle}>
          <SearchIcon is_active />
        </span>
      )}
    </Fragment>
  );
};

const SearchBarImage = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className='d-flex justify-content-center align-items-center'
      style={{ height: '32px', width: '32px', marginTop: '-2px' }}
    >
      <svg
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.8359 11C12.0469 11.2344 12.0469 11.5859 11.8359 11.7969L11.2969 12.3359C11.0859 12.5469 10.7344 12.5469 10.5 12.3359L7.59375 9.42969C6.82031 9.94531 5.88281 10.25 4.875 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.67969 2.17969 0.5 4.875 0.5C7.57031 0.5 9.75 2.67969 9.75 5.375C9.75 6.38281 9.44531 7.32031 8.92969 8.09375L11.8359 11ZM1.875 5.375C1.875 7.03906 3.21094 8.375 4.875 8.375C6.53906 8.375 7.875 7.03906 7.875 5.375C7.875 3.71094 6.53906 2.375 4.875 2.375C3.21094 2.375 1.875 3.71094 1.875 5.375Z'
          fill='#323232'
          fillOpacity='0.4'
        />
      </svg>
    </div>
  );
};
export const SearchBar = ({
  placeholder = 'Search',
  on = 'enter',
  onSearch,
  icon = true,
  width,
}) => {
  const [search, setSearch] = useState('');

  const searchText = useDebounce(search);

  useEffect(() => {
    if (on === 'key') {
      onSearch(searchText);
    }
  }, [on, searchText, onSearch]);

  return (
    <Fragment>
      <div
        className='d-flex'
        style={{
          minWidth: width ? width : '300px',
          height: '32px',
          borderRadius: '16px',
          borderWidth: '2px',
          border: '2px solid #C0C0C0',
        }}
      >
        <SearchBarImage
          onClick={() => {
            onSearch(search);
          }}
        />
        <div
          style={{
            width: '1px',
            background: '#C0C0C0',
            height: '24px',
            marginTop: '2px',
          }}
        />
        <input
          type='text'
          className='ps-2'
          value={search}
          onChange={e => setSearch(e.target.value)}
          style={{
            background: 'transparent',
            border: '0px',
            outline: 'none',
          }}
          placeholder={placeholder}
        />
      </div>
    </Fragment>
  );
};

Search.prototype = {
  placeholder: PropTypes.string,
  on: PropTypes.oneOf(['key', 'enter']),
  onSearch: PropTypes.func.isRequired,
  icon: PropTypes.bool,
};

export default Search;
