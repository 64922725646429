import React from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '../../../components/common/search';
import naadiIcon from '../../../assets/icons/Naadi-icon.svg';

const Header = ({ isCompletedList, setIsCompletedList, onSearch }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 3,
        width: '100%',
      }}
    >
      <div className='ext-dpod-header'>
        <div className='d-flex align-items-center py-16'>
          <div className='mx-16' style={{ width: '24px', height: '24px' }}>
            <img src={naadiIcon} alt='naadiIcon' />
          </div>
          <div className=' title-text rubik-font'>Naadi Digital POD</div>
        </div>
        <div className='status-headers'>
          <div
            onClick={() => setIsCompletedList(false)}
            className={`text ${!isCompletedList ? 'active' : ''} py-16`}
          >
            Pending
          </div>
          <div
            onClick={() => setIsCompletedList(true)}
            className={`text ${isCompletedList ? 'active' : ''} py-16 `}
          >
            Completed
          </div>
        </div>
      </div>

      <div style={{ background: '#EFEFEF' }} className={'py-16 ps-4 pe-4'}>
        <div className={'d-flex align-items-center justify-content-center'}>
          <SearchBar
            placeholder='Search shipments'
            onSearch={onSearch}
            on={'key'}
            width={'100%'}
          />
        </div>
      </div>
    </div>
  );
};

Header.prototype = {
  onClickMenu: PropTypes.func,
  location: PropTypes.object,
  scanState: PropTypes.string,
};

export default Header;
