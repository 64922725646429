/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { Fragment, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faPlus,
  faHouse,
  faCircleXmark,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { OrgLogo } from '../../../components/org/org-logo';
import ScanIcon from '../../../assets/icons/scan-icon.svg';

const ProfilePage = () => {
  const [editField, setEditField] = useState(null);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp] = useState('');
  const [addLanguage, setAddLanguage] = useState(false);
  const [logout, setLogout] = useState(false);
  const [formData, setFormData] = useState({
    name: { firstName: 'jacob', lastName: 'smith' },
    email: 'example@gmail.com',
    phone: '+91 7890123456',
  });
  const handleInputChange = useCallback((field, value) => {
    setFormData(prev => {
      if (field === 'name') {
        return { ...prev, name: { ...prev.name, ...value } };
      }
      return { ...prev, [field]: value };
    });
  });
  const handleAddClick = useCallback(() => {
    setAddLanguage(true);
  });
  const handleCloseClick = useCallback(() => {
    setAddLanguage(false);
  });
  const handleCloseLogout = useCallback(() => {
    setLogout(false);
  });
  const handleLogout = useCallback(() => {
    setLogout(true);
  });

  const handleSave = useCallback(() => {
    setEditField(null);
  });
  const verifyOtp = useCallback(otp => {
    if (otp === '123456') {
      alert('OTP Verified Successfully!');
      setEditField(null);
      setOtpRequested(false);
    } else {
      alert('Invalid OTP. Please try again.');
    }
  });

  const resendOtp = useCallback(() => {
    alert(`OTP has been resent to ${formData.phone}`);
  });

  const editModelContent = useCallback(() => {
    switch (editField) {
      case 'name':
        return (
          <div
            className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg p-3'
            style={{
              height: '80%',
              borderTopLeftRadius: '30px',
              borderTopRightRadius: '30px',
            }}
          >
            <h2 className='mb-3'>Enter Name</h2>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2'
              style={{
                border: '1px solid #ccc',
                borderBottom: '2px solid #006EED',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label'
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  First Name & Middle Name
                </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  value={formData.name.firstName}
                  onChange={e =>
                    handleInputChange('name', { firstName: e.target.value })
                  }
                />
              </div>
              <div className=' d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: '#32323266', paddingLeft: '10px' }}
                />
              </div>
            </div>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2 mt-5'
              style={{
                border: '1px solid #ccc',
                borderBottom: '2px solid #006EED',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label'
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  Second Name
                </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  value={formData.name.lastName}
                  onChange={e =>
                    handleInputChange('name', { lastName: e.target.value })
                  }
                />
              </div>
              <div className=' d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: '#32323266', paddingLeft: '10px' }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between mt-5 gap-2'>
              <button
                className='btn btn-secondary rounded-3 bg-white fs-6'
                style={{
                  width: '48%',
                  color: '#006EED',
                  border: '1px solid #006EED',
                }}
                onClick={() => setEditField(null)}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary rounded-2 fs-6'
                onClick={handleSave}
                style={{ width: '48%' }}
              >
                Save
              </button>
            </div>
          </div>
        );
      case 'email':
        return otpRequested ? (
          <div
            className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg p-3'
            style={{
              height: '80%',
              borderTopLeftRadius: '30px',
              borderTopRightRadius: '30px',
            }}
          >
            <h5>Enter OTP</h5>
            <p className='fw-normal'>OTP has been sent to {formData.email}</p>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2 mt-4'
              style={{
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label '
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  ENTER OTP
                </label>
                <div className='d-flex flex-row gap-2 p-1'>
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mt-5'>
              <button
                className='btn btn-link text-decoration-none'
                style={{
                  width: '48%',
                  color: '#006EED',
                  border: '1px solid #006EED',
                }}
                onClick={resendOtp}
              >
                Resend OTP
              </button>
              <button
                className='btn btn-primary rounded-2 fs-6'
                style={{ width: '48%' }}
                onClick={() => verifyOtp(otp)}
              >
                Sign In
              </button>
            </div>
          </div>
        ) : (
          <div
            className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg p-3'
            style={{
              height: '80%',
              borderTopLeftRadius: '30px',
              borderTopRightRadius: '30px',
            }}
          >
            <h5>Enter Email ID</h5>
            <p className='fw-normal'>
              You&apos;ll receive an OTP on the EMAIL ID to verify
            </p>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2 mt-4'
              style={{
                border: '1px solid #ccc',
                borderBottom: '2px solid #006EED',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label'
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  Email ID
                </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  value={formData.phone}
                  onChange={e => handleInputChange('phone', e.target.value)}
                />
              </div>
              <div className=' d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: '#32323266', paddingLeft: '10px' }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between mt-5 gap-2'>
              <button
                className='btn btn-secondary rounded-3 bg-white fs-6'
                style={{
                  width: '48%',
                  color: '#006EED',
                  border: '1px solid #006EED',
                }}
                onClick={() => setEditField(null)}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary rounded-2 fs-6'
                onClick={() => setOtpRequested(true)}
                style={{ width: '48%' }}
              >
                Verify
              </button>
            </div>
          </div>
        );
      case 'phone':
        return otpRequested ? (
          <div
            className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg p-3'
            style={{
              height: '80%',
              borderTopLeftRadius: '30px',
              borderTopRightRadius: '30px',
            }}
          >
            <h5>Enter OTP</h5>
            <p className='fw-normal'>OTP has been sent to {formData.phone}</p>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2 mt-4'
              style={{
                border: '1px solid #ccc',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label '
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  ENTER OTP
                </label>
                <div className='d-flex flex-row gap-2 p-1'>
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                  <input
                    type='text'
                    maxLength='1'
                    className='otp-input px-1  text-center fs-2'
                    placeholder='&#8226;'
                    style={{
                      width: '50px',
                      border: 'none',
                      borderBottom: '1px solid #006EED',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mt-5'>
              <button
                className='btn btn-link text-decoration-none'
                style={{
                  width: '48%',
                  color: '#006EED',
                  border: '1px solid #006EED',
                }}
                onClick={resendOtp}
              >
                Resend OTP
              </button>
              <button
                className='btn btn-primary rounded-2 fs-6'
                style={{ width: '48%' }}
                onClick={() => verifyOtp(otp)}
              >
                Sign In
              </button>
            </div>
          </div>
        ) : (
          <div
            className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg p-3'
            style={{
              height: '80%',
              borderTopLeftRadius: '30px',
              borderTopRightRadius: '30px',
            }}
          >
            <h5>Enter Phone Number</h5>
            <p className='fw-normal'>You&apos;ll receive an OTP to verify</p>
            <div
              className='mb-3 d-flex flex-row justify-content-between rounded-3 px-2 mt-4'
              style={{
                border: '1px solid #ccc',
                borderBottom: '2px solid #006EED',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition:
                  'box-shadow 0.2s ease, border-bottom-color 0.2s ease',
              }}
            >
              <div>
                <label
                  className='form-label'
                  style={{ fontSize: '11px', color: '#323232CC' }}
                >
                  Enter OTP
                </label>
                <input
                  type='text'
                  className='form-control fs-6'
                  value={formData.phone}
                  onChange={e => handleInputChange('phone', e.target.value)}
                />
              </div>
              <div className=' d-flex justify-content-center align-items-center'>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: '#32323266', paddingLeft: '10px' }}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between mt-5 gap-2'>
              <button
                className='btn btn-secondary rounded-3 bg-white fs-6'
                style={{
                  width: '48%',
                  color: '#006EED',
                  border: '1px solid #006EED',
                }}
                onClick={() => setEditField(null)}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary rounded-2 fs-6'
                onClick={() => setOtpRequested(true)}
                style={{ width: '48%' }}
              >
                Verify
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [editField, formData, handleInputChange, handleSave]);
  return (
    <Fragment>
      <div className='d-flex flex-column vh-100 w-100 bg-white naadi-text gap-3 '>
        <div className='px-16'>
          <h5 className='fs-6 ' style={{ marginTop: '10px' }}>
            My Profile
          </h5>

          <div
            className='d-flex flex-row p-4 rounded-8 gap-3 mt-2 '
            style={{
              backgroundColor: '#F5F5F5',
              borderRadius: '8px',
            }}
          >
            <div
              className='position-relative'
              style={{ width: '80px', height: '80px' }}
            >
              <img
                src='https://via.placeholder.com/60'
                alt='Profile'
                className='rounded-circle img-fluid'
                style={{ height: '100%', width: '100%' }}
              />
              <button
                className='text-white'
                style={{
                  position: 'absolute',
                  top: '80%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '12px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: '5px 10px',
                  borderRadius: '5px',
                }}
              >
                Edit
              </button>
            </div>

            <div className='d-flex flex-column gap-2 roboto-font'>
              <div className='d-flex flex-row gap-2 text-center'>
                <h6 className='fs-5 '>
                  {`${formData.name.firstName} ${formData.name.lastName}`}
                </h6>
                <span>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size='sm'
                    style={{ color: '#006EED', cursor: 'pointer' }}
                    onClick={() => setEditField('name')}
                  />
                </span>
              </div>
              <div className='d-flex flex-row gap-2 text-center'>
                <p className='fs-6'>{formData.email}</p>
                <span>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size='sm'
                    style={{ color: '#006EED', cursor: 'pointer' }}
                    onClick={() => setEditField('email')}
                  />
                </span>
              </div>
              <div className='d-flex flex-row gap-2 text-center'>
                <p className='fs-6 '>{formData.phone}</p>
                <span>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size='sm'
                    style={{ color: '#006EED', cursor: 'pointer' }}
                    onClick={() => setEditField('phone')}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {editField && (
          <div
            className='position-fixed top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            }}
          >
            <div
              className='bg-white p-4 rounded-8'
              style={{
                width: '90%',
                maxWidth: '400px',
              }}
            >
              {editModelContent()}
            </div>
          </div>
        )}
        <div className='d-flex flex-column gap-2 px-16 '>
          <h5 className=''>Preferred Language (2)</h5>
          <div
            className='d-flex flex-wrap gap-3 rounded-10 p-3'
            style={{
              backgroundColor: '#F5F5F5',
            }}
          >
            <div
              className=' px-2 bg-white d-flex flex-row gap-1 text-center rounded-4 justify-content-center align-items-center '
              style={{
                border: '1px solid #006EED',
              }}
            >
              <button
                className='p-2 bg-white border-0'
                style={{ color: '#006EED' }}
              >
                English
              </button>
              <span>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size='sm'
                  style={{ color: '#006EED' }}
                />
              </span>
            </div>
            <div
              className=' px-2 bg-white d-flex flex-row gap-1 text-center rounded-4 justify-content-center align-items-center '
              style={{
                border: '1px solid #006EED',
              }}
            >
              <button
                className='p-2 bg-white border-0'
                style={{ color: '#006EED' }}
              >
                Kannada
              </button>
              <span>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size='sm'
                  style={{ color: '#006EED' }}
                />
              </span>
            </div>
            <div
              className=' px-2 d-flex flex-row gap-1 text-center  justify-content-center align-items-center'
              style={{ backgroundColor: '#027AFF1A' }}
            >
              <button
                className='border-0'
                style={{ backgroundColor: '#027AFF1A', color: '#006EED' }}
                onClick={handleAddClick}
              >
                add
              </button>
              <span>
                <FontAwesomeIcon
                  icon={faPlus}
                  size='sm'
                  style={{ color: '#006EED' }}
                />
              </span>
            </div>
          </div>
        </div>
        {addLanguage && (
          <div
            className='position-fixed top-0 left-0 w-100 h-100'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            }}
          >
            <div
              className='position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg '
              style={{
                height: '60%',
                borderTopLeftRadius: '30px',
                borderTopRightRadius: '30px',
                zIndex: 1001,
              }}
            >
              <div className='p-4 justify-content-center align-items-center'>
                <div className='fs-2 fw-normal pb-2'>
                  Select Preferred Language
                </div>
                <div className='pb-2'>
                  You can select multiple preferred languages
                </div>
                <div className='p-2 pt-3 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>English</div>
                </div>
                <div className='p-2 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>Hindi</div>
                </div>
                <div className='p-2 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>Kannada</div>
                </div>
                <div className='p-2 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>Marathi</div>
                </div>
                <div className='p-2 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>Telugu</div>
                </div>
                <div className='p-2 gap-3 d-flex flex-row'>
                  <input type='checkbox' name='language' value='Kannada' />
                  <div className='fw-normal'>Tamil</div>
                </div>
                <div className='d-flex justify-content-between mt-5 gap-2'>
                  <button
                    className='btn btn-secondary rounded-3 bg-white fs-6'
                    style={{
                      width: '48%',
                      color: '#006EED',
                      border: '1px solid #006EED',
                    }}
                    onClick={handleCloseClick}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary rounded-2 fs-6'
                    onClick={() => setOtpRequested(true)}
                    style={{ width: '48%' }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='px-16'>
          <div className='col '>
            <h5>Others</h5>
            <div className='d-flex flex-column p-2'>
              <button
                className='btn  mb-3 b-0 rounded-2 py-4 '
                style={{
                  backgroundColor: '#F5F5F5',
                  color: '#006EED',
                }}
              >
                Download App
              </button>
              <button
                className='btn  mb-3 b-0 rounded-2 py-4'
                style={{
                  backgroundColor: '#F5F5F5',
                  color: '#006EED',
                }}
              >
                Get Help
              </button>
              <button
                className='btn  mb-3 b-0 text-color rounded-2 py-4'
                style={{
                  backgroundColor: '#F5F5F5',
                  color: '#006EED',
                }}
                onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
        {logout && (
          <div
            className='position-fixed top-0 left-0 w-100 h-100'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            }}
          >
            <div
              className='p-3 position-fixed bottom-0 start-0 end-0 bg-white border-top shadow-lg '
              style={{
                height: '28%',
                borderTopLeftRadius: '30px',
                borderTopRightRadius: '30px',
                zIndex: 1001,
              }}
            >
              <div
                style={{
                  fontSize: '24px',
                  color: '#FFC107',
                  marginBottom: '10px',
                }}
              >
                <div className='pb-2'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    size='2xl'
                    style={{ color: '#FFD43B' }}
                  />
                </div>
              </div>
              <h1>Are you sure?</h1>
              <p className='fw-normal fs-5 '>
                Are you sure you want to logout?
              </p>
              <div className='d-flex justify-content-between pt-3'>
                <button
                  className='btn btn-secondary rounded-3'
                  style={{
                    backgroundColor: '#F5F5F5',
                    color: '#006EED',
                    width: '48%',
                  }}
                  onClick={handleCloseLogout}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-primary rounded-3'
                  style={{
                    backgroundColor: '#006EED',
                    color: 'white',
                    width: '48%',
                  }}
                  onClick={handleCloseLogout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className='naadi-logo d-flex flex-row gap-3 '
          style={{
            marginTop: '40%',
            marginLeft: '40%',
          }}
        >
          <div style={{ height: '24px', width: '24px' }}>
            <OrgLogo />
          </div>
          <p style={{ letterSpacing: '8px' }}>NAADI</p>
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            height: '72px',
            boxShadow: '-4px -4px 4px #0000001A',
            background: 'white',
            width: '100%',
          }}
        >
          <div className='scan-wrapper' onClick={() => {}}>
            <div className='scan-icon'>
              <img
                src={ScanIcon}
                alt='Scan here to ship boxes'
                className='cursor-pointer align-items-center'
                style={{ filter: 'invert(100%) brightness(100%)' }}
              />{' '}
            </div>
          </div>
          <div className='ext-dpod-footer rubik-font'>
            <div className='footer-item'>
              <FontAwesomeIcon
                icon={faHouse}
                style={{ color: '#006EED', width: '24px', height: '24px' }}
              />
              <span>Home</span>
            </div>
            <div className='footer-item'>
              <div style={{ width: '24px', height: '24px' }}></div>
              <span>Scan QR</span>
            </div>
            <div className='footer-item'>
              <div style={{ width: '24px', height: '24px' }}>
                <OrgLogo />
              </div>
              <span>Akhil</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfilePage;
