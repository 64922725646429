/* eslint-disable sonarjs/cognitive-complexity */
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { StatusGreenCompleted } from '../../components/common/status-component';
import scanGif from '../../assets/gif/scan.gif';
import enterNumberGif from '../../assets/gif/enter-number.gif';
import manuallySelectionGif from '../../assets/gif/manually-selection.gif';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Camera from 'react-html5-camera-photo';
import CloseIcon from '../../assets/icons/icon-cancel.svg';
import { Modal } from 'react-bootstrap';
import { RefreshIcon } from '../../assets/icons/nav-icons';
import { FileUploader } from 'react-drag-drop-files';
import {
  TrackTraceImageAddIcon,
  TrackTraceImageRemoveIcon,
} from '../../assets/icons/icon-tracktrace';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { toastError } from '../packing/packingHelper';
import { cloneDeep, isEqual } from 'lodash';
import {
  createDPod,
  getDigitalPodDataExternal,
  getDPodData,
  getExternalDPodScanItems,
  scanExternalDPod,
  searchShipmentCollaborators,
  updateDPod,
} from '../../services/shipment';
import {
  setCollabShipments,
  setCurrentScanItem,
  setDPodExternalState,
  updatePendSyncStateItems,
} from '../../store/digitalpod/dpodexternstationstate';
import { useDispatch, useSelector } from 'react-redux';
import { toastResponseError } from '../common/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { actions } from '@naadi/framework';
import { getState } from '../../store';
const CUSTOM_TOAST_BODY_CLASS = 'custom-toast-body-dpod';

const style = {
  width: 'fit-content',
  backgroundColor: 'black',
  color: 'white',
  zIndex: 9999,
  margin: 'auto',
  bottom: '45%',
};

export const toastScanMode = message => {
  toast(message, {
    autoClose: 2000,
    hideProgressBar: true,
    closeButton: false,
    pauseOnHover: true,
    bodyClassName: CUSTOM_TOAST_BODY_CLASS,
    style,
  });
};

export const toastUndo = message => {
  toast(
    <div className='custom-toast-content'>
      <div className='toast-icon-container'>
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
      <span>{message}</span>
    </div>,
    {
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      bodyClassName: CUSTOM_TOAST_BODY_CLASS,
      style,
    },
  );
};

export const toastSuccess = (message, onUndo, reject_mode) => {
  toast(
    <div className='custom-toast-content'>
      <div className='toast-icon-container'>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
      </div>
      <div className='message-container'>
        <span>{message}</span>
      </div>
      <button className='undo-button' onClick={onUndo}>
        UNDO
      </button>
    </div>,
    {
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: false,
      bodyClassName: CUSTOM_TOAST_BODY_CLASS,
      style: { ...style, backgroundColor: reject_mode ? 'red' : 'green' },
    },
  );
};

export const calculateDPodStat = async (podResult, ctx) => {
  if (!podResult || !podResult.result) return;
  const ctxType = ctx?.type?.value;
  const ctxId = ctx?.value?.key;
  podResult.ctx_type = ctxType;
  podResult.ctx_id = ctxId;
  const ctx_DPodId = ctx?.value?.ctxDPodId;
  const ctx_BranchId = ctx?.value?.ctxBranchId;
  const ctx_OrgId = ctx?.value?.ctxOrgId;
  const stat = {
    box_processed: 0,
    box_total: 0,
    item_processed: 0,
    item_total: 0,
    total_processed: 0,
    total_rejected: 0,
    total: 0,
  };
  console.log(
    '🚀 > calculateDPodStat >ctx_DPodId, podResult.result:',
    ctx_DPodId,
    podResult.result,
  );

  let recordedScanCodes = [];
  if (!podResult?.stat) {
    const res = await getExternalDPodScanItems(
      ctx_OrgId,
      ctx_BranchId,
      ctx_DPodId,
    );
    // console.log('res', res?.payload);
    res?.payload?.forEach(scanItem => {
      if (scanItem?.scan_code && !scanItem?.rejected) {
        recordedScanCodes.push({
          rejected: false,
          scanCode: scanItem?.scan_code,
        });
      } else {
        recordedScanCodes.push({
          rejected: true,
          scanCode: scanItem?.scan_code,
        });
        stat.total_rejected += 1;
      }
    });
  }
  stat.total_processed = stat.item_processed + stat.box_processed;
  const orderItemSummary = (orderItem = {}) => {
    if (!orderItem || !orderItem._operation_status) {
      return {
        qty: orderItem.qty || 0,
        processed: false,
        processed_qty: 0,
        rejected: 0,
        rejected_qty: 0,
      };
    }

    const summary = {
      processed_qty: orderItem.qty || 0,
      rejected_qty: 0,
      processed: false,
    };

    orderItem._operation_status.forEach(
      ({ operation_code, qty, rejected_qty, status }) => {
        if (operation_code === 'DIGITALPOD') {
          summary.processed_qty = qty;
          summary.rejected_qty = rejected_qty || 0;
          if (status === 'SUCCESS') {
            summary.processed = true;
          }
        }
      },
    );
    const foundItemScanned = recordedScanCodes.find(
      item => item.scanCode === orderItem.code && item.rejected === false,
    );
    const foundItemRejected = recordedScanCodes.find(
      item => item.scanCode === orderItem.code && item.rejected === true,
    );

    if (foundItemScanned) {
      summary.processed = true;
      summary.processed_qty = 1;
    } else if (foundItemRejected) {
      summary.rejected_qty = 1;
    }
    return summary;
  };

  const boxItemSummary = box => {
    const boxItems = box.box_items || [];
    const summary = {
      processed: false,
      qty: 0,
      processed_qty: 0,
      rejected_qty: 0,
      item_count: boxItems.length,
    };

    let allProcessed = true;
    let allProcessedQty = true;
    let allRejectQty = true;

    boxItems.forEach(boxItem => {
      if (boxItem.order_box_item) {
        const itemResult = orderItemSummary(boxItem.order_box_item);
        if (!itemResult.processed) allProcessed = false;
        if (itemResult.processed_qty !== 1) allProcessedQty = false;
        if (itemResult.rejected_qty !== 1) allRejectQty = false;
      }
    });

    if (allProcessed) summary.processed = true;
    if (allProcessedQty && boxItems.length > 0) summary.processed_qty = 1;
    if (allRejectQty && boxItems.length > 0) summary.rejected_qty = 1;

    const foundItemScanned = recordedScanCodes.find(
      item => item.scanCode === box.code && item.rejected === false,
    );
    const foundItemRejected = recordedScanCodes.find(
      item => item.scanCode === box.code && item.rejected === true,
    );

    if (foundItemScanned) {
      summary.processed = true;
      summary.processed_qty = 1;
    } else if (foundItemRejected) {
      summary.rejected_qty = 1;
    }
    return summary;
  };

  podResult.result = podResult.result.map(dPodItem => {
    if (dPodItem.type === 'BOX' && dPodItem?.box?.box_items?.length > 0) {
      const boxResult = boxItemSummary(dPodItem.box);

      stat.box_total++;
      stat.box_processed += boxResult.processed ? 1 : 0;

      return {
        ...dPodItem,
        box: {
          ...dPodItem.box,
          processed_qty: boxResult.processed_qty,
          rejected_qty: boxResult.rejected_qty,
          item_count: boxResult.item_count,
          processed: boxResult.processed,
        },
      };
    } else if (dPodItem.type === 'ORDER_ITEM') {
      const itemResult = orderItemSummary(dPodItem.order_item);

      stat.item_total++;
      stat.item_processed += itemResult.processed_qty;

      return {
        ...dPodItem,
        order_item: {
          ...dPodItem.order_item,
          processed: itemResult.processed,
          processed_qty: itemResult.processed_qty,
          rejected_qty: itemResult.rejected_qty,
        },
      };
    }

    return dPodItem;
  });
  const extraItem = podResult?.result?.filter(
    v =>
      (v.box === undefined || v.box === null) &&
      (v.order_item === undefined || v.order_item === null),
  );
  console.log('🚀 > calculateDPodStat > extraItem:', extraItem);
  stat.total_processed = stat.item_processed + stat.box_processed;
  stat.total = podResult.result.length;
  podResult.stat = stat;
  return podResult;
};

const extractBase64Data = imgs => {
  return imgs.map(item => {
    const base64Prefix = 'data:image/jpeg;base64,';
    const base64PrefixPNG = 'data:image/png;base64,';
    let base64Data = item.data;
    if (base64Data.startsWith(base64Prefix)) {
      base64Data = base64Data.substring(base64Prefix.length);
    } else if (base64Data.startsWith(base64PrefixPNG)) {
      base64Data = base64Data.substring(base64PrefixPNG.length);
    }

    return {
      file_name: item.name,
      caption: '',
      data: base64Data,
    };
  });
};
export const updatePodState = (dPodState, text) => {
  let matchFound = false;
  let currentDpod = null;
  let undoItem = null;
  let currentItem = null;
  const updatedState = cloneDeep(dPodState)?.map(pod => {
    if (matchFound) return pod;
    const updatedResults = pod?.result?.map(result => {
      if (matchFound) return result;
      if (
        (result?.box?.code === text && result?.box?.processed) ||
        (result?.order_item?.code === text && result?.order_item?.processed)
      ) {
        matchFound = true;
        toastError(`${text} already delivered `);
        return result;
      }
      if (result?.box?.code === text && !result?.box?.processed) {
        matchFound = true;
        pod.stat.box_processed += 1;
        pod.stat.total_processed += 1;
        console.log(
          'result?.box?.rejected_qty-before',
          result?.box?.rejected_qty,
        );
        if (result?.box?.rejected_qty > 0) {
          console.log(
            'result?.box?.rejected_qty-after',
            result?.box?.rejected_qty,
          );
          pod.stat.total_rejected -= 1;
        }
        currentDpod = pod;
        undoItem = { ...result };
        currentItem = {
          ...result,
          box: {
            ...result?.box,
            processed: true,
            processed_qty: 1,
            rejected_qty: 0,
          },
        };
        return currentItem;
      } else if (
        result?.order_item?.code === text &&
        !result?.order_item?.processed
      ) {
        pod.stat.item_processed += 1;
        pod.stat.total_processed += 1;
        if (result?.order_item?.rejected_qty > 0) {
          pod.stat.total_rejected -= 1;
        }
        matchFound = true;
        currentDpod = pod;
        undoItem = { ...result };
        currentItem = {
          ...result,
          order_item: {
            ...result?.order_item,
            processed: true,
            processed_qty: 1,
            rejected_qty: 0,
          },
        };
        return currentItem;
      }
      return result;
    });

    return {
      ...pod,
      result: updatedResults,
    };
  });
  if (!currentDpod && matchFound) {
    matchFound = false;
  } else if (!matchFound) {
    toastError(`${text} does not exist in digital pod`);
  }
  return { updatedState, matchFound, currentDpod, currentItem, undoItem };
};

// export const updateOrdersDetails = (dPodUpdatedState, currentUpdate, text) => {
//   let updatedOrder = null;

//   const updatedState = dPodUpdatedState.map(v => {
//     if (v.dPod_id === currentUpdate.dPod_id) {
//       const {
//         updatedState: updatedOrdersDetailsTemp,
//         matchFound,
//         currentDpod: currentOrder,
//       } = updatePodState(v.ordersDetails, text);

//       if (matchFound) {
//         updatedOrder = currentOrder;
//         return {
//           ...v,
//           ordersDetails: updatedOrdersDetailsTemp,
//         };
//       }
//     }
//     return v;
//   });

//   return { updatedState, updatedOrder };
// };
export const verifyRejectScan = (dPodState, text) => {
  let matchFound = false;
  let currentDpod = null;
  let currentItem = null;
  dPodState.forEach(pod => {
    if (matchFound) return;

    pod?.result?.forEach(result => {
      if (matchFound) return;

      if (
        (result?.box?.code === text && result?.box?.rejected_qty > 0) ||
        (result?.order_item?.code === text &&
          result?.order_item?.rejected_qty > 0)
      ) {
        matchFound = true;
        toastError(`${text} is already rejected`);
        return;
      }

      if (result?.box?.code === text && !result?.box?.rejected_qty) {
        matchFound = true;
        currentDpod = pod;
        currentItem = {
          ...result,
          box: {
            ...result?.box,
            rejected_qty: 1,
          },
        };
      }

      if (
        result?.order_item?.code === text &&
        !result?.order_item?.rejected_qty
      ) {
        matchFound = true;
        currentDpod = pod;
        currentItem = {
          ...result,
          order_item: {
            ...result?.order_item,
            rejected_qty: 1,
          },
        };
      }
    });
  });

  if (matchFound && !currentDpod) {
    matchFound = false;
  } else if (!matchFound) {
    toastError(`${text} does not exist in the digital pod`);
  }

  return { matchFound, currentDpod, currentItem };
};

export const updatePodRejectedState = (dPodState, text) => {
  let matchFound = false;
  let currentDpod = null;
  let currentItem = null;
  let undoItem = null;

  const updatedState = cloneDeep(dPodState)?.map(pod => {
    if (matchFound) return pod;

    const updatedResults = pod?.result?.map(result => {
      if (matchFound) return result;

      if (
        (result?.box?.code === text && result?.box?.rejected_qty > 0) ||
        (result?.order_item?.code === text &&
          result?.order_item?.rejected_qty > 0)
      ) {
        matchFound = true;
        return result;
      }

      if (result?.box?.code === text && !result?.box?.rejected_qty) {
        matchFound = true;
        pod.stat.total_rejected += 1;
        if (result?.box?.processed && result?.box?.processed_qty > 0) {
          console.log('box.processed_qty', result?.box?.processed_qty);
          pod.stat.total_processed -= 1;
          pod.stat.box_processed -= 1;
        }
        currentDpod = pod;
        undoItem = { ...result };
        currentItem = {
          ...result,
          box: {
            ...result?.box,
            rejected_qty: 1,
            processed: false,
            processed_qty: 0,
          },
        };
        return currentItem;
      }
      if (
        result?.order_item?.code === text &&
        !result?.order_item?.rejected_qty
      ) {
        matchFound = true;
        currentDpod = pod;
        undoItem = { ...result };
        pod.stat.total_rejected += 1;
        if (
          result?.order_item?.processed &&
          result?.order_item?.processed_qty > 0
        ) {
          pod.stat.total_processed -= 1;
          pod.stat.item_processed -= 1;
        }

        currentItem = {
          ...result,
          order_item: {
            ...result?.order_item,
            rejected_qty: 1,
            processed: false,
            processed_qty: 0,
          },
        };
        return currentItem;
      }

      return result;
    });

    return {
      ...pod,
      result: updatedResults,
    };
  });
  if (!currentDpod && matchFound) {
    matchFound = false;
  }
  return { updatedState, matchFound, currentDpod, currentItem, undoItem };
};
// export const updateOrdersRejectedDetails = (
//   dPodUpdatedState,
//   currentUpdate,
//   text,
// ) => {
//   let updatedOrder = null;
//   const updatedState = dPodUpdatedState.map(v => {
//     if (v.dPod_id === currentUpdate?.dPod_id) {
//       const {
//         updatedState: updatedOrdersDetailsTemp,
//         matchFound,
//         currentDpod,
//       } = updatePodRejectedState(v.ordersDetails, text);

//       if (matchFound) {
//         updatedOrder = currentDpod;
//         return {
//           ...v,
//           ordersDetails: updatedOrdersDetailsTemp,
//         };
//       }
//     }
//     return v;
//   });

//   return { updatedState, updatedOrder };
// };
export const undoLastScan = (dPodState, undoItem) => {
  let currentDpod = null;
  let matchFound = false;

  const updatedState = cloneDeep(dPodState).map(pod => {
    if (matchFound) return pod;

    console.log('ENTERED');
    const updatedResults = pod.result.map(result => {
      if (matchFound) return result;
      if (
        (undoItem.type === 'BOX' && result.box?.uuid === undoItem.box?.uuid) ||
        (undoItem.type === 'ORDER_ITEM' &&
          result.order_item?.uuid === undoItem.order_item?.uuid)
      ) {
        if (undoItem.type === 'BOX') {
          console.log('undoItem.box.processed_qty', result.box.processed_qty);
          pod.stat.box_processed -= result.box.processed_qty;
          console.log('pod.stat.box_processed', pod.stat.box_processed);

          pod.stat.total_processed -= result.box.processed_qty;
          pod.stat.total_rejected -= result.box.rejected_qty;

          pod.stat.box_processed += undoItem.box.processed_qty;
          pod.stat.total_processed += undoItem.box.processed_qty;
          pod.stat.total_rejected += undoItem.box.rejected_qty;
        } else if (undoItem.type === 'ORDER_ITEM') {
          pod.stat.item_processed -= result.order_item.processed_qty;
          pod.stat.total_processed -= result.order_item.processed_qty;
          pod.stat.total_rejected -= result.order_item.rejected_qty;
          pod.stat.item_processed += undoItem.order_item.processed_qty;
          pod.stat.total_processed += undoItem.order_item.processed_qty;
          pod.stat.total_rejected += undoItem.order_item.rejected_qty;
        }
        matchFound = true;
        currentDpod = pod;
        console.log('currentDpod', currentDpod);
        return { ...undoItem };
      }
      return result;
    });

    console.log('pod.stat', pod.stat);
    return {
      ...pod,
      result: updatedResults,
    };
  });

  console.log('undoLastScan-output', updatedState, matchFound, currentDpod);

  return { updatedState, matchFound, currentDpod };
};

const processOrdersDetails = (
  operationFn,
  dPodState,
  currentPod,
  text,
  undoItem,
) => {
  let updatedOrder = null;
  console.log('currentPod', currentPod);
  const updatedState = dPodState.map(pod => {
    if (pod.dPod_id !== currentPod?.dPod_id) return pod;

    const operationArgs = undoItem
      ? [pod?.ordersDetails, undoItem]
      : [pod?.ordersDetails, text];

    const {
      updatedState: updatedResults,
      matchFound,
      currentDpod: updatedPod,
    } = operationFn(...operationArgs);

    if (!matchFound) return pod;

    updatedOrder = updatedPod;

    return {
      ...pod,
      ordersDetails: updatedResults,
    };
  });
  return { updatedState, updatedOrder };
};

export const updateOrdersDetails = (dPodState, currentUpdate, text) => {
  return processOrdersDetails(updatePodState, dPodState, currentUpdate, text);
};
export const updateOrdersRejectedDetails = (dPodState, currentUpdate, text) => {
  return processOrdersDetails(
    updatePodRejectedState,
    dPodState,
    currentUpdate,
    text,
  );
};
export const undoOrdersDetails = (dPodState, currentPod, undoItem) => {
  return processOrdersDetails(
    undoLastScan,
    dPodState,
    currentPod,
    undefined,
    undoItem,
  );
};

export const handelOnlineScan = async (currentUpdate, scanItem) => {
  if (!currentUpdate && !scanItem) {
    return null;
  }
  let text;
  let reasons = [];
  let value = '';
  let images = [];
  let rejected = false;
  let deleted = false;
  if (scanItem.box) {
    text = scanItem.box?.code;
    reasons = scanItem.box?.reasons || [];
    value = scanItem.box?.value || '';
    images = scanItem.box?.images || [];
    rejected = scanItem.box?.rejected_qty > 0 ? true : false;
    deleted =
      scanItem.box?.rejected_qty > 0 || scanItem.box?.processed_qty > 0
        ? false
        : true;
  } else if (scanItem.order_item) {
    text = scanItem.order_item?.code;
    reasons = scanItem.order_item?.reasons || [];
    value = scanItem.order_item?.value || '';
    images = scanItem.order_item?.images || [];
    rejected = scanItem.order_item?.rejected_qty > 0 ? true : false;
    deleted =
      scanItem.order_item?.rejected_qty > 0 ||
      scanItem.order_item?.processed_qty > 0
        ? false
        : true;
  }
  const req_payload = [
    {
      scan_code: text,
      manual_entry: false,
      qty: 1,
      message: value || '',
      reject_reason: reasons || [],
      rejected: rejected ? true : false,
      active: true,
      deleted: deleted,
      approved: true,
      rejected_qty: rejected ? 1 : 0,
      base64_images: images,
    },
  ];
  try {
    const payload = {
      active: true,
      deleted: false,
      approved: true,
      req_payload,
    };
    const res = await scanExternalDPod(
      currentUpdate.org_id,
      currentUpdate.dPod_id,
      payload,
    );
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error('scan failed:', err);
    return [];
  }
};
export const onUndoHandler = async (
  state,
  currentPod,
  undoItem,
  setUpdatedScanState,
  isOnline,
  dispatch,
) => {
  console.log(
    'onUndoHandler',
    state,
    currentPod,
    undoItem,
    setUpdatedScanState,
    isOnline,
    dispatch,
  );
  const { updatedState, matchFound, currentDpod } = undoLastScan(
    state,
    undoItem,
  );
  console.log('onUndo-1', updatedState, matchFound, currentDpod);
  let uploadStatus = false;

  if (!matchFound) return;
  const { updatedState: fullyUpdatedState, updatedOrder: fullyUpdatedOrder } =
    undoOrdersDetails(updatedState, currentPod, undoItem);
  console.log('onUndo-2', fullyUpdatedState);
  currentDpod.ordersDetails = fullyUpdatedOrder;
  if (setUpdatedScanState) {
    setUpdatedScanState(currentDpod);
  }
  try {
    if (isOnline) {
      const res = await handelOnlineScan(currentDpod, undoItem);
      if (res.status !== 200) {
        return;
      }
      uploadStatus = true;
    }
  } catch (error) {
    toastError(error);
  } finally {
    if (uploadStatus) {
      dispatch(setDPodExternalState(fullyUpdatedState));
      toastUndo(`Undo successful`);
    }
  }
};

const resetStatusHelp = dPodExternalState => {
  return cloneDeep(dPodExternalState).map(d => {
    return {
      ...d,
      stat: {
        ...d.stat,
        box_processed: 0,
        item_processed: 0,
        total_processed: 0,
        total_rejected: 0,
      },
      result: d.result.map(r => {
        if (r.box) {
          return {
            ...r,
            box: {
              ...r.box,
              processed: false,
              processed_qty: 0,
              rejected_qty: 0,
            },
          };
        } else if (r.order_item) {
          return {
            ...r,
            order_item: {
              ...r.order_item,
              processed: false,
              processed_qty: 0,
              rejected_qty: 0,
            },
          };
        }
        return r;
      }),
    };
  });
};
export const resetStatusStat = (dPodExternalState, dPodDetails) => {
  // Update the pod in one iteration
  return dPodExternalState.map(pod => {
    if (pod.dPod_id !== dPodDetails?.dPod_id) {
      return pod;
    }
    const updatedPod = resetStatusHelp([pod]);
    console.log('ordersDetails', resetStatusHelp(pod.ordersDetails));
    updatedPod[0].ordersDetails = resetStatusHelp(pod.ordersDetails);
    return updatedPod[0]; // Return the updated pod
  });
};

const DeliveryMethodCard = ({
  method,
  selectedMethod,
  setSelectedMethod,
  imageSrc,
  title,
  description,
  activeCardBorderStyle,
  activeCardBgStyle,
  cardBgStyle,
}) => {
  const isActive = selectedMethod === method;

  return (
    <div
      onClick={() => setSelectedMethod(method)}
      className={`w-100 py-16 px-16`}
      style={{
        borderRadius: '16px',
        border: isActive ? activeCardBorderStyle : 'none',
        background: isActive ? activeCardBgStyle : cardBgStyle,
        cursor: 'pointer',
      }}
    >
      <div className='rubik-font'>
        <div className='d-flex justify-content-between'>
          <div
            className='d-flex justify-content-center align-items-center rounded-circle'
            style={{
              width: '60px',
              height: '60px',
              overflow: 'hidden',
            }}
          >
            <img
              src={imageSrc}
              alt={`${method} gif`}
              style={{
                width: '120px',
                height: 'auto',
              }}
            />
          </div>
          {isActive && (
            <div
              className='naadi-status bg-success text-white'
              style={{
                width: 'fit-content',
                padding: '4px',
              }}
            >
              <div>SELECTED</div>
              <StatusGreenCompleted fill={'white'} />
            </div>
          )}
        </div>
        <div>
          <div
            style={{ fontFamily: 'Roboto' }}
            className='fw-bold fs-6 text-primary my-8'
          >
            {title}
          </div>
          <span>{description}</span>
        </div>
      </div>
    </div>
  );
};

export const DeliveryMethodModal = ({ setBottomDrawerModal }) => {
  const dispatch = useDispatch();
  const activeCardBorderStyle = '2px solid #027AFF';
  const activeCardBgStyle = '#027AFF1A';
  const cardBgStyle = '#F5F5F5';
  const history = useHistory();
  const { dPodExternalState, selectedPendingCard } = useSelector(
    state => state.dpodexternstationstate,
  );
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
  return (
    <div className='row col-lg-12 col-md-9 py-16'>
      <div className='col-12 d-flex flex-column gap-3 rubik-font'>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '500',
          }}
          className='pb-16'
        >
          Choose Delivery Method
        </p>

        <DeliveryMethodCard
          method='scan'
          selectedMethod={selectedDeliveryMethod}
          setSelectedMethod={setSelectedDeliveryMethod}
          imageSrc={scanGif}
          title='Scan'
          description='Serialized: use camera for scanning barcodes'
          activeCardBorderStyle={activeCardBorderStyle}
          activeCardBgStyle={activeCardBgStyle}
          cardBgStyle={cardBgStyle}
        />

        <DeliveryMethodCard
          method='select_boxes_manually'
          selectedMethod={selectedDeliveryMethod}
          setSelectedMethod={setSelectedDeliveryMethod}
          imageSrc={manuallySelectionGif}
          title='Select boxes manually'
          description='Serialized: Tap to mark serialized boxes'
          activeCardBorderStyle={activeCardBorderStyle}
          activeCardBgStyle={activeCardBgStyle}
          cardBgStyle={cardBgStyle}
        />

        <DeliveryMethodCard
          method='enter_number_of_boxes'
          selectedMethod={selectedDeliveryMethod}
          setSelectedMethod={setSelectedDeliveryMethod}
          imageSrc={enterNumberGif}
          title='Enter Number of Boxes'
          description='Non-Serialized: Just number of boxes delivered'
          activeCardBorderStyle={activeCardBorderStyle}
          activeCardBgStyle={activeCardBgStyle}
          cardBgStyle={cardBgStyle}
        />

        <div
          className='d-flex justify-content-between w-100'
          style={{ gap: '1rem' }}
        >
          <button
            className='btn btn-outline-primary btn-lg'
            style={{
              background: 'white',
              width: '100%',
              height: '48px',
            }}
            onClick={() => {
              setBottomDrawerModal(false);
            }}
          >
            Back
          </button>
          <button
            className='btn btn-outline-primary btn-lg bg-primary text-white'
            style={{
              width: '100%',
              height: '48px',
            }}
            onClick={() => {
              if (selectedDeliveryMethod) {
                setSelectedDeliveryMethod('');
                setBottomDrawerModal(false);

                const updatedState = dPodExternalState.map(v =>
                  v.dPod_id === selectedPendingCard.dPod_id
                    ? {
                        ...v,
                        delivery_method: selectedDeliveryMethod,
                      }
                    : v,
                );

                dispatch(setDPodExternalState(updatedState));

                history.push(
                  `/app/dpodexternal/details/${selectedPendingCard.dPod_id}`,
                );
              } else {
                toastError('Please select a delivery method');
              }
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};
const readFileData = async file => {
  return await new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = evt => {
      const baseURL = reader.result;
      resolve(baseURL);
    };
    reader.readAsDataURL(file);
  });
};
export const ImageListModal = memo(
  ({
    showImageSelectionModal,
    dPodDetails,
    setEntityModal,
    setBottomDrawerModal,
    images,
    onCloseCb,
    onImageUpdate,
    onConfirmCb,
    onNewImage,
    onImageUploadClick,
    setShowImageSelectionModal,
    setShowImageListModal,
  }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const isOnline = navigator.onLine;
    const dispatch = useDispatch();
    const { reject_mode, dPodExternalState, currentScanItem } = useSelector(
      state => {
        return state.dpodexternstationstate;
      },
    );
    const getThumbnailObject = useCallback(
      leftIndex => {
        const showCount = 4;
        if (leftIndex >= images.length) {
          leftIndex = images.length - 1;
        } else if (leftIndex < 0) {
          leftIndex = 0;
        }

        const to = {
          leftIndex: leftIndex,
          images: [],
          show: showCount,
        };
        for (let i = 0; i < images.length; i++) {
          if (i < leftIndex || i >= leftIndex + showCount) {
            continue;
          }
          to.images.push({
            index: i,
            more: 0,
          });
        }
        if (images.length > to.images.length && to.images.length > 1) {
          const first = to.images[0];
          const last = to.images[to.images.length - 1];
          first.more = first.index;
          last.more = images.length - first.index - showCount;
        }
        return to;
      },
      [images],
    );
    useEffect(() => {
      setCurrentImage(ci => {
        if (ci >= images.length) {
          return Math.max(0, images.length - 1);
        }
        return ci;
      });
    }, [images]);
    const [thumbnail, setThumbnail] = useState(getThumbnailObject(0));
    useEffect(() => {
      setThumbnail(to => {
        return getThumbnailObject(to.leftIndex);
      });
    }, [getThumbnailObject, setThumbnail]);
    const onImageSelect = useCallback(
      (image, index) => {
        setCurrentImage(image.index);
        if (image.more > 0 && index === 0 && image.index > 0) {
          setThumbnail(tn => {
            return getThumbnailObject(tn.leftIndex - 1);
          });
        } else if (
          image.more > 0 &&
          index > 0 &&
          image.index < images.length - 1
        ) {
          setThumbnail(tn => {
            return getThumbnailObject(tn.leftIndex + 1);
          });
        }
      },
      [setThumbnail, getThumbnailObject, images],
    );
    const handleFileDrop = useCallback(
      async file => {
        if (
          ['jpeg', 'png', 'jpg'].filter(val => file.type.indexOf(val) >= 0)
            .length === 0
        ) {
          return;
        }
        const imageData = await readFileData(file);
        onImageUpdate([
          ...images,
          { data: imageData, name: file.name, type: file.type },
        ]);
      },
      [images, onImageUpdate],
    );
    const handleUpdateDPod = async () => {
      try {
        const imagesData = extractBase64Data(images);
        if (isOnline) {
          if (reject_mode) {
            const scanItem = cloneDeep(currentScanItem);
            if (scanItem.order_item) {
              scanItem.order_item.images = imagesData;
            } else if (scanItem.box) {
              scanItem.box.images = imagesData;
            }
            setBottomDrawerModal(false);
            dispatch(setCurrentScanItem(scanItem));
            setEntityModal('REJECTION_SUBMIT');
            return;
          }
          const req_payload = {
            shipment_id: dPodDetails?.ctx_id,
            branch_id: dPodDetails?.shipments?.[dPodDetails?.ctx_id]?.branch_id,
            status: 'PENDING',
          };

          const res = await updateDPod(
            dPodDetails?.org_id,
            dPodDetails?.dPod_id,
            imagesData,
            req_payload,
          );

          if (!res || res.status !== 200) {
            console.log('Image update to DPod failed');
          } else {
            console.log('Image update to DPod succeeded');
          }
          setEntityModal('confirmTheDetails');
        } else {
          const copyDPodExternalState = cloneDeep(dPodExternalState);
          if (reject_mode) {
            const scanItem = cloneDeep(currentScanItem);
            if (scanItem.order_item) {
              scanItem.order_item.images = imagesData;
            } else if (scanItem.box) {
              scanItem.box.images = imagesData;
            }
            setBottomDrawerModal(false);
            dispatch(setCurrentScanItem(scanItem));
            setEntityModal('REJECTION_SUBMIT');
          } else {
            const pendingItem = copyDPodExternalState
              .filter(v => v.dPod_id === dPodDetails?.dPod_id)
              .flatMap(v =>
                v.result.find(result => {
                  if (result?.type === 'BOX') {
                    return {
                      ...result,
                      box: {
                        ...result?.box,
                        images: imagesData,
                      },
                    };
                  } else if (result?.type === 'ORDER_ITEM') {
                    return {
                      ...result,
                      order_item: {
                        ...result?.order_item,
                        images: imagesData,
                      },
                    };
                  }
                  return null;
                }),
              );

            setEntityModal('confirmTheDetails');
            dispatch(updatePendSyncStateItems(dPodDetails, pendingItem));
          }
        }

        if (onConfirmCb) {
          onConfirmCb(images);
        }
      } catch (error) {
        console.error('Error during DPod update:', error);
      } finally {
        setShowImageSelectionModal(false);
        setShowImageListModal(false);
      }
    };
    return (
      <Fragment>
        <div>
          <Modal
            centered={true}
            show={true}
            onHide={onCloseCb}
            fullscreen={true}
          >
            <Modal.Header
              className='d-flex justify-content-between '
              style={{ borderBottom: '0px' }}
            >
              <div>
                <Modal.Title>Add Images</Modal.Title>
              </div>
              <div>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  style={{ color: 'black', width: '20px' }}
                  onClick={onCloseCb}
                ></button>
              </div>
            </Modal.Header>

            <Modal.Body className='px-16 py-16'>
              <div className='swiper-body-container'>
                {(!images || images.length === 0) && (
                  <div className='px-16'>
                    <div className='bulk-upload-import-content '>
                      <FileUploader
                        name={'fileupload'}
                        handleChange={handleFileDrop}
                        onDrop={handleFileDrop}
                        multiple={false}
                        types={['png', 'jpeg', 'jpg']}
                        classes='w-100'
                      >
                        <div className='w-100'>
                          <div className='w-100 text-center pt-4'>
                            <svg
                              width='83'
                              height='59'
                              viewBox='0 0 83 59'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.1'
                                d='M18.8125 58.125C8.5 58.125 0.25 49.875 0.25 39.5625C0.25 31.5703 5.40625 24.6094 12.625 22.1602C12.625 21.7734 12.625 21.3867 12.625 21C12.625 9.65625 21.7773 0.375 33.25 0.375C40.8555 0.375 47.5586 4.62891 51.0391 10.8164C52.9727 9.39844 55.4219 8.625 58 8.625C64.832 8.625 70.375 14.168 70.375 21C70.375 22.6758 69.9883 24.0938 69.4727 25.5117C77.0781 27.0586 82.75 33.7617 82.75 41.625C82.75 50.7773 75.2734 58.125 66.25 58.125H18.8125ZM28.9961 30.1523C27.707 31.4414 27.707 33.375 28.9961 34.5352C30.1562 35.8242 32.0898 35.8242 33.25 34.5352L38.4062 29.5078V46.7812C38.4062 48.5859 39.6953 49.875 41.5 49.875C43.1758 49.875 44.5938 48.5859 44.5938 46.7812V29.5078L49.6211 34.5352C50.7812 35.8242 52.7148 35.8242 53.875 34.5352C55.1641 33.375 55.1641 31.4414 53.875 30.1523L43.5625 19.8398C42.4023 18.6797 40.4688 18.6797 39.3086 19.8398L28.9961 30.1523Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <div className='w-100 text-center bulk-upload-drag-drop-text'>
                            <div>Drag and Drop Files Here</div>
                          </div>
                          <div className='bulk-upload-upload-separator'>
                            <div className='row'>
                              <div className='col d-flex align-items-center'>
                                <div></div>
                              </div>
                              <div className='or'>or</div>
                              <div className='col d-flex align-items-center'>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div className='bulk-upload-browse-file cursor-pointer'>
                            <div
                              className='text-center'
                              onClick={() => {
                                if (onImageUploadClick) {
                                  onImageUploadClick();
                                }
                              }}
                            >
                              <span className={`'me-2'`}>Browse File</span>

                              <span className=''>
                                <svg
                                  width='19'
                                  height='14'
                                  viewBox='0 0 19 14'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M4.625 5H15V3.5C15 2.6875 14.3125 2 13.5 2H8.5L6.5 0H1.5C0.6875 0 0 0.6875 0 1.5V11.75L2.84375 6.09375C3.15625 5.4375 3.84375 5 4.625 5ZM17 6H4.625C4.25 6 3.90625 6.21875 3.71875 6.5625L0 14H14C14.375 14 14.7188 13.7812 14.9062 13.4375L17.9062 7.4375C18.2188 6.78125 17.75 6 17 6Z'
                                    fill='#006EED'
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                  </div>
                )}
                {images && images.length > 0 && (
                  <div className='swiper-container'>
                    <Swiper
                      key={currentImage}
                      modules={[Pagination]}
                      initialSlide={currentImage}
                      spaceBetween={20}
                      pagination={{
                        clickable: true,
                        // dynamicBullets: true,
                      }}
                      slidesPerView={1}
                      style={{ width: '100%', height: 'auto' }}
                    >
                      {images.map((image, index) => (
                        <SwiperSlide
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span className='text-center'>
                            <img
                              src={image.data}
                              alt={`${index + 1}`}
                              style={{
                                width: '100%',
                                maxHeight: '400px',
                                objectFit: 'contain',
                              }}
                            />
                          </span>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer
              className='position-relative '
              style={{ borderTop: '0px' }}
            >
              <div className='w-100 '>
                <div className='d-flex px-32'>
                  {onNewImage && (
                    <div className='me-3'>
                      <div
                        className='d-flex align-items-center position-relative justify-content-center cursor-pointer'
                        style={{
                          width: '56px',
                          height: '56px',
                          backgroundColor: 'rgba(2, 122, 255,0.1)',
                        }}
                        onClick={onNewImage}
                      >
                        <TrackTraceImageAddIcon />
                      </div>
                    </div>
                  )}
                  {thumbnail &&
                    thumbnail.images &&
                    thumbnail.images.length > 0 &&
                    thumbnail.images.map((image, index) => {
                      let text = '';
                      let showMore = false;
                      if (index === 0 && image.more > 0) {
                        text = '-' + image.more;
                        showMore = true;
                      }
                      if (
                        index === thumbnail.images.length - 1 &&
                        image.more > 0
                      ) {
                        text = '+' + image.more;
                        showMore = true;
                      }
                      return (
                        <div className='me-3' key={image.index}>
                          <div
                            className='position-relative justify-content-center cursor-pointer'
                            style={{
                              width: '56px',
                              height: '56px',
                              backgroundColor: '#000',
                            }}
                            onClick={() => {
                              onImageSelect(image, index);
                            }}
                          >
                            <img
                              style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%',
                              }}
                              src={
                                images[`${image.index}`]
                                  ? images[`${image.index}`].data
                                  : null
                              }
                              alt={` ${image.index}`}
                            />
                            {showMore && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '18px',
                                  left: '14px',
                                  fontSize: '18px',
                                  fontFamily: 'Rubik',
                                }}
                              >
                                {text}
                              </div>
                            )}
                            {!showMore && onImageUpdate && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '-10px',
                                  right: '-12px',
                                }}
                              >
                                <TrackTraceImageRemoveIcon
                                  onClick={() => {
                                    const _images = [...images];
                                    _images.splice(image.index, 1);
                                    onImageUpdate(_images);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {onNewImage && (
                    <div className='d-flex gap-3   justify-content-between w-100 mt-5'>
                      <button
                        className='naadi-back-btn py-16'
                        onClick={() => {
                          if (onCloseCb) {
                            onCloseCb();
                          }
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className='naadi-save-btn py-16'
                        onClick={handleUpdateDPod}
                      >
                        {reject_mode ? 'Finish' : 'Next'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Footer>
            {showImageSelectionModal && (
              <span className='background-overlay p-0 m-0'></span>
            )}
          </Modal>
        </div>
      </Fragment>
    );
  },
);
export const ImagesUploader = memo(
  ({ dPodDetails, setEntityModal, setBottomDrawerModal }) => {
    const [showImageSelectionModal, setShowImageSelectionModal] =
      useState(true);
    const [showImageListModal, setShowImageListModal] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [cameraImageData, setCameraImageData] = useState('');
    const [localImages, setLocalImages] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const [cameraFacingMode, setCameraFacingMode] = useState('environment');
    const [cameraModeStyle] = useState({
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      position: 'fixed',
      zIndex: '100000',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
    });
    const handleUploadInputChange = useCallback(async event => {
      const files = event.target.files;
      const newImages = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[`${i}`];
        if (
          ['jpeg', 'png', 'jpg'].filter(val => file.type.indexOf(val) >= 0)
            .length === 0
        ) {
          continue;
        }
        const imageData = await readFileData(file);
        newImages.push({
          data: imageData,
          name: file.name,
          type: file.type,
        });
      }
      if (newImages.length > 0) {
        setLocalImages(i => {
          return [...i, ...newImages];
        });
        setShowImageListModal(true);
        setShowImageSelectionModal(false);
      }
    }, []);

    const onSaveImages = useCallback(() => {
      setLocalImages(imageList => {
        return [
          ...imageList,
          {
            data: cameraImageData,
            name: 'Image ' + (imageList.length + 1),
            type: 'image/png',
          },
        ];
      });
      setCameraImageData('');
      setShowCamera(false);
      setShowImageListModal(true);
      setShowImageSelectionModal(false);
    }, [cameraImageData]);
    return (
      <Fragment>
        {showImageListModal && (
          <>
            <ImageListModal
              showImageSelectionModal={showImageSelectionModal}
              dPodDetails={dPodDetails}
              setEntityModal={setEntityModal}
              setBottomDrawerModal={setBottomDrawerModal}
              images={localImages}
              onImageUploadClick={() => {
                hiddenFileInput.current.click();
                setShowImageSelectionModal(false);
              }}
              onImageUpdate={imageList => {
                setLocalImages(imageList);
              }}
              onNewImage={() => {
                setShowImageSelectionModal(true);
              }}
              onConfirmCb={() => {}}
              onCloseCb={() => {
                setShowImageSelectionModal(false);
                setShowImageListModal(false);
                setBottomDrawerModal(false);
              }}
              setShowImageSelectionModal={setShowImageSelectionModal}
              setShowImageListModal={setShowImageListModal}
            />
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg'
              ref={hiddenFileInput}
              onChange={handleUploadInputChange}
              style={{ display: 'none' }}
              multiple
            />
          </>
        )}
        {showCamera && cameraImageData !== '' && (
          <Modal
            show={showCamera}
            onHide={() => {
              setShowCamera(false);
            }}
            dialogClassName='m-0'
          >
            <div
              className='p-32 track-trace-camera-image'
              style={cameraModeStyle}
            >
              <div className='w-100 text-center'>
                <img
                  style={{
                    height: 'calc(100vh - 100px)',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                  src={cameraImageData}
                  alt='camera'
                />
                <div className='d-flex justify-content-end track-trace-camera-image-action'>
                  <div className='px-16 py-16'>
                    <div
                      className='btn btn-outline-primary'
                      onClick={() => {
                        setCameraImageData('');
                      }}
                    >
                      Delete
                    </div>
                  </div>
                  <div className='px-16 py-16'>
                    <div
                      className='btn btn-primary'
                      onClick={() => {
                        onSaveImages();
                      }}
                    >
                      Accept
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {showCamera && cameraImageData === '' && (
          <Modal
            show={showCamera}
            onHide={() => {
              setShowCamera(false);
            }}
            dialogClassName='m-0'
          >
            <div className='d-flex' style={cameraModeStyle}>
              <div className='m-auto'>
                <Camera
                  style={{ margin: 'auto' }}
                  onTakePhoto={dataUri => {
                    setCameraImageData(dataUri);
                  }}
                  idealFacingMode={cameraFacingMode}
                  isFullscreen={true}
                />
              </div>
              <div
                className='cursor-pointer'
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '16px',
                  padding: '8px',
                  backgroundColor: 'rgba(0, 0, 0,0.2)',
                  borderRadius: '32px',
                }}
              >
                <img
                  src={CloseIcon}
                  width='32px'
                  alt='Close'
                  onClick={() => {
                    setShowCamera(false);
                    setShowImageListModal(true);
                    setShowImageSelectionModal(false);
                  }}
                />
              </div>
              <div
                className='cursor-pointer'
                style={{
                  position: 'absolute',
                  right: '48px',
                  bottom: '48px',
                  backgroundColor: 'rgba(255, 255, 255,0.3)',
                }}
              >
                <RefreshIcon
                  onClick={() => {
                    setCameraFacingMode(val =>
                      val === 'environment' ? 'user' : 'environment',
                    );
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        {showImageSelectionModal && (
          <Modal
            show={showImageSelectionModal}
            onHide={() => {
              showImageSelectionModal(false);
            }}
            dialogClassName='m-0'
          >
            <div
              style={{
                height: 'auto',
                position: 'fixed',
                bottom: 2,
                left: 0,
                boxShadow: '-4px -4px 4px #0000001A',
                background: 'white',
                width: '100%',
                borderTopRightRadius: '16px',
                borderTopLeftRadius: '16px',
                zIndex: 2000,
              }}
            >
              <div className='py-32'>
                <div className='flex-column rubik-font'>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {'Add image'}
                  </p>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      color: '#006eed',
                      fontWeight: 400,
                    }}
                    className='mt-5'
                  >
                    <div
                      onClick={() => {
                        setShowCamera(true);
                      }}
                    >
                      Open Camera{' '}
                    </div>
                    <hr style={{ backgroundColor: 'black' }} />
                    <div
                      onClick={() => {
                        hiddenFileInput.current.click();
                      }}
                    >
                      Upload From Files{' '}
                    </div>
                    <input
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      ref={hiddenFileInput}
                      onChange={handleUploadInputChange}
                      style={{ display: 'none' }}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  },
);
const getDPodShipmentOrders = async (dPodResult, dPod_id) => {
  if (!dPodResult || !dPodResult.result || dPodResult.result.length === 0) {
    return [];
  }

  const { result, orders } = dPodResult;
  const ordersGroupedByOrderId = {};
  for (const item of result) {
    const { order_id } = item;
    if (!ordersGroupedByOrderId[`${order_id}`]) {
      ordersGroupedByOrderId[`${order_id}`] = [];
    }
    ordersGroupedByOrderId[`${order_id}`].push(item);
  }

  const groupedOrders = [];

  for (const orderId in ordersGroupedByOrderId) {
    if (Object.hasOwnProperty.call(ordersGroupedByOrderId, orderId)) {
      groupedOrders.push({
        order_id: orderId,
        result: ordersGroupedByOrderId[`${orderId}`],
      });
    }
  }

  const orderDetails = [];

  for (const group of groupedOrders) {
    const ctx = {
      type: {
        label: 'order',
        value: 'ORDER',
      },
      value: {
        key: group.order_id,
        ctxDPodId: dPod_id,
        ctxBranchId: orders[group?.order_id]?.branch_id,
        ctxOrgId: orders[group?.order_id]?.org_id,
      },
    };

    const res = await calculateDPodStat(group, ctx);
    const matchingOrder = orders[group.order_id];

    if (matchingOrder) {
      orderDetails.push({
        order: matchingOrder,
        stat: res.stat,
        result: res.result,
      });
    }
  }
  return orderDetails;
};
const processDpodResponse = dPodResp => {
  return {
    shipments: dPodResp?.meta_data?.shipments || {},
    orders: dPodResp?.meta_data?.orders || {},
    result:
      dPodResp?.payload?.filter(v => v.shipment_id !== 'NO_SHIPMENT') || [],
  };
};

const processNewState = async (
  result,
  ctx,
  ctxId,
  ctxLabel,
  ctxOrgId,
  ctxBranchId,
  ctxDPodId,
  dPodStatus,
) => {
  const calcResult = await calculateDPodStat(result, {
    ...ctx,
    value: {
      key: ctxId,
      label: ctxLabel,
      org_id: ctxOrgId,
      ctxDPodId,
      ctxBranchId,
      ctxOrgId,
    },
  });
  return {
    ...calcResult,
    dPod_id: ctxDPodId,
    org_id: ctxOrgId,
    branch_id: ctxBranchId,
    dPOd_status: dPodStatus,
    delivery_method: null,
    ordersDetails: await getDPodShipmentOrders(calcResult, ctxDPodId),
  };
};
const processExistingState = (
  existingState,
  result,
  ctx,
  ctxId,
  ctxLabel,
  ctxOrgId,
  ctxBranchId,
  ctxDPodId,
  dPodStatus,
) => {
  const existingOrders =
    existingState && Object.keys(existingState?.orders || {});
  const newOrders = result && Object.keys(result.orders);

  if (existingOrders.every((orderId, i) => orderId === newOrders[`${i}`])) {
    return {
      ...existingState,
      shipment: result.shipments,
      orders: result.orders,
    };
  }

  return processNewState(
    result,
    ctx,
    ctxId,
    ctxLabel,
    ctxOrgId,
    ctxBranchId,
    ctxDPodId,
    dPodStatus,
  );
};

const processSingleShipment = async (
  shipment,
  skipCache,
  dPodExternalState,
  ctx,
  defaultFeatures,
  current_station,
) => {
  const {
    uuid: ctxId,
    code: ctxLabel,
    org_id: ctxOrgId,
    branch_id: ctxBranchId,
  } = shipment.shipment || {};

  const reqPayload = {
    shipment_id: ctxId,
    branch_id: ctxBranchId,
    status: 'PENDING',
  };

  let ctxDPodId = '';
  let dPodStatus = '';
  const dpodRes = await getDPodData(ctxOrgId, ctxId);
  if (dpodRes?.status === 200) {
    ctxDPodId = dpodRes?.payload?.uuid;
    dPodStatus = dpodRes?.payload?.status;
  }

  const dPodData = await getDigitalPodDataExternal(
    ctxOrgId,
    ctx.type.value,
    ctxId,
    defaultFeatures,
    current_station.operations,
    skipCache,
  );
  const shipment_ids = dPodData?.payload?.filter(
    v => v.shipment_id !== 'NO_SHIPMENT',
  ).length;
  if (dPodData?.payload?.length === 0 || shipment_ids === 0) {
    return {};
  }
  if (dPodData.status !== 200) {
    toastResponseError(dPodData, 'Unable to Fetch the Context Data');
    return {};
  } else if (
    dPodData.status === 200 &&
    dPodData.payload.length &&
    dpodRes?.status !== 200
  ) {
    const dPodResponse = await createDPod(ctxOrgId, reqPayload);
    if (dPodResponse.status === 200) {
      ctxDPodId = dPodResponse?.payload?.uuid;
      dPodStatus = dPodResponse?.payload?.status;
    } else {
      console.log(`${ctxLabel} dPod_id creation failed`);
    }
  }
  if (!ctxDPodId) {
    return {};
  }
  const result = processDpodResponse(dPodData);
  console.log('🚀 > result:', result);
  const existingState = dPodExternalState?.find(
    dpse => dpse.dPod_id === ctxDPodId,
  );
  if (existingState) {
    return processExistingState(
      existingState,
      result,
      ctx,
      ctxId,
      ctxLabel,
      ctxOrgId,
      ctxBranchId,
      ctxDPodId,
      dPodStatus,
    );
  }
  return processNewState(
    result,
    ctx,
    ctxId,
    ctxLabel,
    ctxOrgId,
    ctxBranchId,
    ctxDPodId,
    dPodStatus,
  );
};

export const processShipments = async (
  shipmentPayload,
  collabShipments,
  skipCache,
  dPodExternalState,
  ctx,
  defaultFeatures,
  current_station,
) => {
  const results = [];
  for (const shipment of shipmentPayload) {
    console.log('🚀 > shipment:', shipment);
    if (shipment.shipment.status === 'CLOSED') {
      console.log(
        '🚀 >   shipmentPayload,collabShipments',
        shipmentPayload,
        collabShipments,
      );
      const foundIndex = collabShipments.findIndex(collabShipment =>
        isEqual(shipment, collabShipment),
      );
      console.log('🚀 > foundIndex:', foundIndex);
      if (foundIndex === -1) {
        console.log('got new shipment or modified shipment');
        const result = await processSingleShipment(
          shipment,
          skipCache,
          dPodExternalState,
          ctx,
          defaultFeatures,
          current_station,
        );
        console.log('🚀 > result:', result);
        if (result) results.push(result);
      }
    }
  }
  console.log('🚀 > results:', results);
  return results?.filter(obj => Object.keys(obj).length > 0);
};

export const populateDigitalPodData = async (term, dispatch, infiniteReq) => {
  console.log('🚀 > populateDigitalPodData:', populateDigitalPodData);
  const {
    dpodexternstationstate: {
      collabShipments,
      dPodExternalState,
      ctx,
      defaultFeatures,
      current_station,
    },
  } = getState();
  console.log('🚀 > current_station:', current_station);
  console.log('🚀 > defaultFeatures:', defaultFeatures);
  console.log('🚀 > ctx:', ctx);
  console.log('🚀 > dPodExternalState:', dPodExternalState);
  console.log('🚀 > collabShipments:', collabShipments);
  try {
    dispatch(actions.busy.add('GET-D-POD-DATA'));
    // Fetch collaborator shipments
    const collaboratorShipments = await searchShipmentCollaborators(
      term,
      infiniteReq?.size,
    );
    if (collaboratorShipments.status !== 200) return;

    const shipmentPayload = collaboratorShipments.payload;
    console.log('🚀populateDigitalPodData > shipmentPayload:', shipmentPayload);

    // Process the fetched shipments
    const dPodResults = await processShipments(
      shipmentPayload,
      collabShipments,
      true,
      dPodExternalState,
      ctx,
      defaultFeatures,
      current_station,
    );

    if (dPodResults?.length > 0) {
      console.log('Updating state with dPod results.');
      const diff = dPodResults.filter(
        dpr => !dPodExternalState.some(dpse => dpse.dPod_id === dpr.dPod_id),
      );
      const updatedState = [...diff, ...dPodExternalState];
      dispatch(setDPodExternalState(updatedState));
      dispatch(setCollabShipments(shipmentPayload));
    }
  } catch (error) {
    console.error('Error populating digital pod data:', error);
  } finally {
    dispatch(actions.busy.remove('GET-D-POD-DATA'));
  }
};
