import React, { Fragment, useCallback } from 'react';
const StatusYellow = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.6875 5.25C9.6875 2.65234 7.59766 0.5625 5 0.5625C2.40234 0.5625 0.3125 2.65234 0.3125 5.25C0.3125 7.84766 2.40234 9.9375 5 9.9375C7.59766 9.9375 9.6875 7.84766 9.6875 5.25Z'
        fill='#FFC700'
      />
    </svg>
  );
};

const StatusBlueDot = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0.5625C2.40234 0.5625 0.3125 2.65234 0.3125 5.25C0.3125 7.84766 2.40234 9.9375 5 9.9375C7.59766 9.9375 9.6875 7.84766 9.6875 5.25C9.6875 2.65234 7.59766 0.5625 5 0.5625ZM6.875 5.25C6.875 6.28516 6.03516 7.125 5 7.125C3.96484 7.125 3.125 6.28516 3.125 5.25C3.125 4.21484 3.96484 3.375 5 3.375C6.03516 3.375 6.875 4.21484 6.875 5.25Z'
        fill='#027AFF'
      />
    </svg>
  );
};

export const StatusGreenCompleted = ({ fill }) => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0.5625C2.40234 0.5625 0.3125 2.65234 0.3125 5.25C0.3125 7.84766 2.40234 9.9375 5 9.9375C7.59766 9.9375 9.6875 7.84766 9.6875 5.25C9.6875 2.65234 7.59766 0.5625 5 0.5625ZM7.26562 4.39062L4.76562 6.89062C4.64844 6.98828 4.51172 7.04688 4.375 7.04688C4.23828 7.04688 4.10156 6.98828 3.98438 6.89062L2.73438 5.64062C2.51953 5.42578 2.51953 5.07422 2.73438 4.85938C2.94922 4.64453 3.30078 4.64453 3.51562 4.85938L4.375 5.71875L6.48438 3.60938C6.69922 3.39453 7.05078 3.39453 7.26562 3.60938C7.48047 3.82422 7.48047 4.17578 7.26562 4.39062Z'
        fill={fill ? fill : '#008A50'}
      />
    </svg>
  );
};

const StatusRedError = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.6875 5.25C9.6875 2.65234 7.59766 0.5625 5 0.5625C2.40234 0.5625 0.3125 2.65234 0.3125 5.25C0.3125 7.84766 2.40234 9.9375 5 9.9375C7.59766 9.9375 9.6875 7.84766 9.6875 5.25Z'
        fill='#c00000'
      />
    </svg>
  );
};

export const StatusComponent = ({ statusMap, statusText, statusKey }) => {
  const renderStatusIcon = useCallback(() => {
    const mappedStatusKey = statusMap[`${statusKey}`] || 'GREEN';
    switch (mappedStatusKey) {
      case 'YELLOW':
        return <StatusYellow />;
      case 'BLUE_DOT':
        return <StatusBlueDot />;
      case 'GREEN':
        return <StatusGreenCompleted />;
      case 'RED':
        return <StatusRedError />;
      default:
        return <StatusGreenCompleted />;
    }
  }, [statusMap, statusKey]);
  return (
    <Fragment>
      <div className='naadi-status'>
        {renderStatusIcon()}
        <div>{statusText}</div>
      </div>
    </Fragment>
  );
};
