/* eslint-disable sonarjs/cognitive-complexity */
import BottomDrawer from '.';
import React from 'react';
const BottomDrawerInit = ({
  name,
  entity,
  isMobile,
  data,
  showImageModal,
  setShowImageModal,
  showModulesModal,
  setShowModulesModal,
  previewImage,
  setPreviewImage,
  showUsersModal,
  setShowUsersModal,
  handleDrawerClose,
  showMenuModal,
  setShowMenuModal,
  branchCreationModal,
  setBranchCreationModal,
  menuModal,
  setMenuModal,
}) => {
  const renderActiveBottomDrawer = () => {
    // console.log('entity', entity, entity.substring(0, entity.indexOf('_')));
    if (entity.includes('menuModal')) {
      return (
        <BottomDrawer
          entity={entity.substring(0, entity.indexOf('_'))}
          drawerTitle={entity.substring(entity.indexOf('_') + 1)}
          handleClose={() => {
            if (handleDrawerClose) handleDrawerClose();
            if (!handleDrawerClose) setShowMenuModal(false);
          }}
          popUp={showMenuModal && isMobile}
          drawerBody={data(name)}
        />
      );
    } else {
      switch (entity) {
        case (entity = 'imageModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle={'Add/Edit Logo'}
              handleClose={() => setShowImageModal(false)}
              popUp={showImageModal && isMobile}
              drawerBody={data('showImageModal')}
            />
          );
        case (entity = 'showMenuModal'):
          return (
            <BottomDrawer
              entity={entity}
              handleClose={() => setMenuModal(false)}
              popUp={menuModal && isMobile}
              drawerBody={data('showMenuModal')}
            />
          );
        case (entity = 'branchCreationModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle='Create branch'
              handleClose={() => setBranchCreationModal(false)}
              popUp={branchCreationModal && isMobile}
              drawerBody={data('showbranchCreationModal')}
            />
          );
        case (entity = 'moduleModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle='Select Modules'
              handleClose={() => setShowModulesModal(false)}
              popUp={showModulesModal && isMobile}
              drawerBody={data('showModulesModal')}
            />
          );
        case (entity = 'imagePreviewModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle='View Logo'
              handleClose={() => setPreviewImage(false)}
              popUp={previewImage && isMobile}
              drawerBody={data('previewImage')}
            />
          );
        case (entity = 'usersModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle='Select Users'
              handleClose={() => {
                if (handleDrawerClose) handleDrawerClose();
                if (!handleDrawerClose) setShowUsersModal(false);
              }}
              popUp={showUsersModal && isMobile}
              drawerBody={data('showUsersModal')}
            />
          );
        case (entity = 'profilesModal'):
          return (
            <BottomDrawer
              entity={entity}
              drawerTitle='Select Users '
              handleClose={() => {
                if (handleDrawerClose) handleDrawerClose();
                if (!handleDrawerClose) setShowUsersModal(false);
              }}
              popUp={showUsersModal && isMobile}
              drawerBody={data('showUsersModal')}
            />
          );
        case (entity = ''):
          return null;
        default:
          return 'Wrong Choice!';
      }
    }
  };
  return <div>{renderActiveBottomDrawer()}</div>;
};

export default BottomDrawerInit;
