import { createAction, createReducer } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';

const SHIPMENT_NAME = 'Shipment';
const SHIPMENT_KEY = 'SHIPMENT';

export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('SHIPMENT_STATE', type, verb);

export const TYPES = {
  SET_NEW_MODE: 'SET_NEW_MODE',
  CURRENT_STATION: 'CURRENT_STATION',
  CHECKBOX_SOUND: 'CHECKBOX_SOUND',
  REFRESH_CACHE: 'REFRESH_CACHE',
};

export const updateToNewShipmentMode = createAction(
  getActionName(TYPES.SET_NEW_MODE, 'RESET'),
);

export const setShipmentCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const setShipmentBranchCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'BRANCHSET'),
);

export const resetShipmentCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);
export const setCheckBoxSound = createAction(
  getActionName(TYPES.CHECKBOX_SOUND, 'SET'),
);
export const setShipmentRefreshCache = createAction(
  getActionName(TYPES.REFRESH_CACHE, 'SET'),
);

export const DEFAULT = {
  new_shipment_mode: true,
  current_station: {
    uuid: 'shipment',
    station_code: 'shipment',
    operations: ['shipment'],
  },
  branch_stations: {},
  checkbox_sound: '',
  refresh_cache: false,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(updateToNewShipmentMode, (state, { payload }) => {
    return Update(state, {
      new_shipment_mode: { $set: payload === true },
    });
  });
  builder.addCase(resetShipmentCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setShipmentCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations);
    let ctx = cloneDeep(state.ctx) || {};
    const current_station = state.current_station;
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    if (
      current_station &&
      ctx &&
      ctx.value &&
      ctx.value.branch_id !== current_station.branch_id
    ) {
      ctx = {
        type: { label: SHIPMENT_NAME, value: SHIPMENT_KEY },
        value: {},
      };
    }
    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
      ctx: { $set: ctx },
    });
  });
  builder.addCase(setShipmentBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
  builder.addCase(setCheckBoxSound, (state, { payload }) => {
    return Update(state, {
      checkbox_sound: { $set: payload },
    });
  });
  builder.addCase(setShipmentRefreshCache, (state, { payload }) => {
    return Update(state, {
      refresh_cache: { $set: payload === true },
    });
  });
});

export default reducer;
