import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faEllipsisVertical,
  faQrcode,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import SubHeader from './sub-header';

function ManualDelivery({ onBack }) {
  const [deliveredFlag] = useState(false);
  const [damagedFlag] = useState(true);
  const [delivered, setDelivered] = useState([]);
  const [damaged, setDamaged] = useState([]);
  const handleNumberClick = itemNumber => {
    if (deliveredFlag) {
      setDelivered(prev =>
        prev.includes(itemNumber) ? prev : [...prev, itemNumber],
      );
      setDamaged(prev => prev.filter(num => num !== itemNumber));
    } else if (damagedFlag) {
      setDamaged(prev =>
        prev.includes(itemNumber) ? prev : [...prev, itemNumber],
      );
      setDelivered(prev => prev.filter(num => num !== itemNumber));
    }
  };

  return (
    <>
      <div
        className='position-fixed top-0 left-0 w-100 vh-100 justify-content-between'
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
          fontFamily: 'Rubik',
        }}
      >
        <div className='d-flex flex-column vh-100 vw-100'>
          <div
            className='wrapper1 gap-2 rounded-bottom bg-white pb-3 w-100'
            style={{
              zIndex: 1001,
              height: '255px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div className='px-3 d-flex flex-row justify-content-between pt-4 w-100 '>
              <div className='d-flex flex-row gap-3'>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{ color: '#000000' }}
                  onClick={onBack}
                />
                <p className='fs-5 text-center'>Order Name_1</p>
              </div>
              <div className='fs-6 text-center'>
                <FontAwesomeIcon
                  className='fs-6 fw-900'
                  icon={faEllipsisVertical}
                  style={{ color: '#070808' }}
                />
              </div>
            </div>
            <div className='pt-2 w-100'>
              <SubHeader statusLabel='Total Items' />
            </div>
          </div>
          <div
            className='back'
            style={{ backgroundColor: '#F5F5F5', minHeight: '100vh' }}
          >
            <div
              className='p-3 wrapper2 gap-2 grids w-100'
              style={{
                zIndex: 1000,
                height: '360px',
              }}
            >
              {[...Array(33)].map((_, index) => {
                const itemNumber = index + 1;
                const isDelivered = delivered.includes(itemNumber);
                const isDamaged = damaged.includes(itemNumber);

                return (
                  <div
                    key={itemNumber}
                    className='p-1 gap-2 fs-6 radius40 d-flex justify-content-center align-items-center position-relative'
                    style={{
                      width: '48px',
                      height: '48px',
                      border: `1px solid ${
                        isDelivered
                          ? '#008A50'
                          : isDamaged
                          ? '#C00000'
                          : '#027AFF'
                      }`,
                      borderRadius: '50%',
                      backgroundColor: `${
                        isDelivered
                          ? '#008A5033'
                          : isDamaged
                          ? '#D700000A'
                          : 'white'
                      }`,
                      color: 'black',
                    }}
                    onClick={() => handleNumberClick(itemNumber)}
                  >
                    {itemNumber}
                    {(isDelivered || isDamaged) && (
                      <FontAwesomeIcon
                        icon={isDelivered ? faCheckCircle : faExclamationCircle}
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          fontSize: '16px',
                          color: isDelivered ? '#008A50' : '#C00000',
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className='mt-5 gap-3 d-flex flex-column w-100 justify-content-center align-items-center position-fixed bottom-0 start-0 end-0'
              style={{ height: '132px' }}
            >
              <div
                className='d-flex flex-row gap-1 text-white pxx2 justify-content-center align-items-center'
                style={{
                  backgroundColor: '#006EED',
                }}
              >
                <span
                  className='naadi-text fw-400 text-center d-flex justify-content-center align-items-center'
                  style={{
                    width: '38px',
                    height: '30px',
                    color: '#FFFFFF',
                  }}
                >
                  Scan
                </span>
                <div
                  className='d-flex justify-content-center align-items-center text-white'
                  style={{
                    color: '#ffffff',
                    backgroundColor: '#006EED',
                    width: '30px',
                    height: '30px',
                  }}
                >
                  <FontAwesomeIcon className='fw-900' icon={faQrcode} />
                </div>
              </div>
              <div
                className='d-flex flex-column gap-2 px22 bg-white w-100 custom-gap'
                style={{ height: '70px', zIndex: 1001, width: '360px' }}
              >
                <div
                  className='naadi-text p-2 radius d-flex justify-content-center align-items-center'
                  style={{ height: '46px' }}
                  onClick={() => {
                    console.log('Delivered:', delivered);
                    console.log('Damaged:', damaged);
                  }}
                >
                  Save and Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManualDelivery;
