import React from 'react';
import { StatusComponent } from '../common/status-component';
import { CutGuideWoCardStat } from '../../views/cutguide/common';

const OrderCard = ({ order, scanned }) => {
  if (!order) return null;
  let statusText = '';
  let statusKey = '';
  if (
    order?.stat?.box_processed === order?.stat?.box_total &&
    order?.stat?.item_processed === order?.stat?.item_total
  ) {
    statusText = 'COMPLETED';
    statusKey = 'COMPLETED';
  } else if (
    order?.stat?.box_processed > 0 ||
    order?.stat?.item_processed > 0
  ) {
    statusText = 'IN_PROGRESS';
    statusKey = 'IN_PROGRESS';
  } else {
    statusText = 'PENDING';
    statusKey = 'PENDING';
  }
  return (
    <div
      style={{ border: scanned ? '2px solid green' : '' }}
      className='d-pod-card-container'
    >
      <div className='px-16 py-16 rubik-font'>
        <div className='d-pod-card'>
          <div className='d-flex align-items-center justify-content-between'>
            <div
              style={{
                width: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className='d-pod-card-title fontRoboto'
            >
              {order?.order?.ref_code}
            </div>
            <div>
              <div className='d-flex justify-content-end'>
                <StatusComponent
                  statusMap={{
                    IN_PROGRESS: 'YELLOW',
                    PENDING: 'BLUE_DOT',
                    COMPLETED: 'GREEN',
                  }}
                  statusText={statusText}
                  statusKey={statusKey}
                />
              </div>
            </div>
          </div>

          <div className='d-flex gap-3 mt-3'>
            <CutGuideWoCardStat
              statCount={order?.stat?.box_processed}
              totalCount={order?.stat?.box_total}
              label='BOXES'
            />
            <CutGuideWoCardStat
              statCount={order?.stat?.item_processed}
              totalCount={order?.stat?.item_total}
              label='NON-BOXED'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
