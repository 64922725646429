import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import QrReader from 'react-qr-scanner';
import { CameraRotateIcon } from '../../assets/icons/common-icons';
import { toastError } from '../../helpers/packing/packingHelper';

export const QrcodeScanModal = ({ onCloseCb, onSubmit }) => {
  const [defaultCamera, setDefaultCamera] = useState(1);
  const [cameras, setCameras] = useState([]);
  const mediaRequested = useRef(false);
  //eslint-disable-next-line
  const [secureHost, setSecureHost] = useState(false);
  const toggleCamera = useCallback(async () => {
    setDefaultCamera(prev => prev + 1);
  }, [setDefaultCamera]);
  const [cameraModeStyle] = useState({
    top: '10px',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
  });
  const lastScan = useRef({
    text: '',
    scanTime: 0,
  });
  useEffect(() => {
    const fn = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const _cameras = devices.filter(device => device.kind === 'videoinput');
      setCameras(_cameras);
    };
    fn();
    if (
      window.location.protocol === 'https:' ||
      ['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1
    ) {
      setSecureHost(true);
    } else {
      toastError('Use the https protocol to use the camera');
    }
  }, [setCameras, setSecureHost]);
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'xl'}
        show={true}
        onHide={onCloseCb}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header
          className='px-32'
          style={{ borderBottom: '0px' }}
          closeButton
        >
          <CameraRotateIcon className='cursor-pointer' onClick={toggleCamera} />
          {cameras.length === 0
            ? ''
            : cameras[defaultCamera % cameras.length].label}
        </Modal.Header>
        <Modal.Body className='px-16 py-16 '>
          {secureHost && (
            <div className='d-flex align-items-center h-100'>
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0px',
                  left: '0px',
                }}
              >
                <div className='tt-qr-scanner-line'></div>
              </div>
              <QrReader
                constraints={{
                  video: {
                    deviceId: {
                      exact:
                        cameras.length === 0
                          ? null
                          : cameras[defaultCamera % cameras.length].deviceId,
                    },
                  },
                  audio: false,
                }}
                style={cameraModeStyle}
                onError={err => {
                  if (
                    err + '' === 'OverconstrainedError' &&
                    mediaRequested.current === false
                  ) {
                    mediaRequested.current = true;
                    navigator.getUserMedia(
                      { video: true },
                      () => {
                        toastError(
                          'Camera Enabled. If the image is not displayed, close it once and reopen',
                        );
                        //onCloseCb();
                      },
                      () => {},
                    );
                  }
                }}
                onScan={data => {
                  if (
                    lastScan.current.scanTime > 0 &&
                    new Date().getTime() - lastScan.current.scanTime > 10000
                  ) {
                    lastScan.current.scanTime = 0;
                    lastScan.current.text = '';
                  }
                  if (
                    data &&
                    data.text &&
                    data.text.length > 0 &&
                    data.text !== lastScan.current.text
                  ) {
                    const text = data.text.trim();
                    lastScan.current.text = text;
                    lastScan.current.scanTime = new Date().getTime();
                    onSubmit(text, false);
                    onCloseCb();
                  }
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default QrcodeScanModal;
