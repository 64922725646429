import service from './index';
import { getState } from '../store';
import ResponseCodes from '@naadi/framework/src/types/ResponseCodes';

export const getOrderBoxes = async order_id => {
  const {
    user: { org, branch },
  } = getState();
  const filter = {
    page: 0,
    size: 100,
    org_id: org.uuid,
    req_payload: {
      packing_context: ['ORDER'],
      packing_context_ref: [order_id],
      branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
      deleted: [false],
    },
  };
  const resp = await getBoxesWithFilter(filter);
  if (resp.status !== ResponseCodes.SUCCESS) {
    return resp;
  }
  resp.size = filter.size * resp.total_pages;
  //const totalPages = resp.total_pages;
  resp.total_pages = 1;
  filter.page++;
  let lastSize = resp.payload.length;
  while (lastSize >= filter.size) {
    const _resp = await getBoxesWithFilter(filter);
    if (_resp.status !== ResponseCodes.SUCCESS) {
      return _resp;
    }
    resp.payload.push(..._resp.payload);
    filter.page++;
    lastSize = _resp.payload.length;
  }
  return resp;
};

export const getBoxesWithFilter = filter => {
  const {
    user: { org, branch },
  } = getState();
  filter.org_id = org.uuid;
  if (filter.req_payload === undefined) {
    filter.req_payload = {};
  }
  filter.req_payload.org_id = org.uuid;
  filter.req_payload.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  if (!filter.size) {
    filter.size = 1000;
  }
  if (!filter.page) {
    filter.page = 0;
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxes/filter`,
    data: filter,
  });
};
export const getOrderBoxItems = async order_id => {
  const {
    user: { org, branch },
  } = getState();
  const filter = {
    page: 0,
    size: 100,
    org_id: org.uuid,
    req_payload: {
      packing_context: ['ORDER'],
      packing_context_ref: [order_id],
      deleted: [false],
      branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
    },
  };
  const resp = await getBoxItemsWithFilter(filter);
  if (resp.status !== ResponseCodes.SUCCESS) {
    return resp;
  }
  resp.size = filter.size * resp.total_pages;
  //const totalPages = resp.total_pages;
  resp.total_pages = 1;
  filter.page++;
  let lastSize = resp.payload.length;
  while (lastSize >= filter.size && lastSize > 0) {
    const _resp = await getBoxItemsWithFilter(filter);
    if (_resp.status !== ResponseCodes.SUCCESS) {
      return _resp;
    }
    lastSize = _resp.payload.length || 0;
    resp.payload.push(..._resp.payload);
    filter.page++;
  }
  return resp;
};

export const getAllBoxItemsWithFilter = async filter => {
  filter.page = 0;
  const size = !filter.size || filter.size <= 0 ? 100 : filter.size;
  let loopCount = 0;
  filter.size = size;
  if (!filter.sort || filter.sort.length === 0) {
    filter.sort = [{ column: 'id' }];
  }
  const resp = {
    status: 200,
    payload: [],
  };
  while (true) {
    if (loopCount > 50) {
      return resp;
    }
    const boxItemResp = await getBoxItemsWithFilter(filter);
    if (boxItemResp.status !== 200) {
      return boxItemResp;
    }
    resp.payload = [...resp.payload, ...boxItemResp.payload];
    filter.page++;
    loopCount++;
    if (boxItemResp.payload.length < size) {
      break;
    }
  }
  return resp;
};

export const getBoxItemsWithFilter = async filter => {
  const {
    user: { org, branch },
  } = getState();
  filter.org_id = org.uuid;
  if (filter.req_payload === undefined) {
    filter.req_payload = {};
  }
  filter.req_payload.org_id = org.uuid;
  filter.req_payload.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxitems/filter`,
    data: filter,
  });
};
export const reopenDeletedBox = box => {
  const {
    user: { org, user_id },
  } = getState();
  const reqData = {
    org_id: org.uuid,
    user_id: user_id,
    active: true,
    approved: true,
    deleted: false,
    req_payload: {
      uuid: box.uuid,
      deleted: false,
      index: box.index,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxes/create`,
    data: reqData,
  });
};
export const createOrAddNewBox = box => {
  const {
    user: { org, user_id, branch },
    selection,
  } = getState();
  const pod = selection?.current_station?.uuid || 'packing';
  const podName = selection?.current_station?.instance_name || 'packing';
  const reqData = {
    org_id: org.uuid,
    user_id: user_id,
    active: true,
    approved: true,
    deleted: box.deleted,
    req_payload: {
      uuid: box.uuid,
      org_id: org.uuid,
      packing_context: box.packing_context,
      packing_context_ref: box.packing_context_ref,
      package_type: box.package_type,
      print_status: box.print_status,
      box_index: box.box_index,
      strict_inventory: box.strict_inventory,
      editable: box.editable,
      branch_id: branch && branch.branch_id ? branch.branch_id : null,
      pod: pod,
      pod_name: podName,
    },
  };
  if (box.code !== undefined && box.uuid !== box.code) {
    reqData.req_payload.code = box.code;
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxes/create`,
    data: reqData,
  });
};

export const getPrintedBoxResult = async (box, printerId) => {
  const {
    user: { org, user_id },
  } = getState();

  const printerParam = printerId ? '&printer=' + printerId : '';
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/boxes/print/${box.uuid}?user_id=${user_id}${printerParam}`,
    data: {},
  });
};
