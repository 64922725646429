import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { MinusCircleIcon, PlusCircleIcon } from '../../../assets/icons';

const ManualEntry = ({ order, onCancel, submitHandler }) => {
  const [boxedItem, setBoxItem] = useState({
    delivered: order?.filter(item => item.box?.processed_qty > 0)?.length || 0,
    damaged: order?.filter(item => item.box?.rejected_qty > 0)?.length || 0,
  });
  const [nonBoxItem, setNonBoxItem] = useState({
    delivered:
      order?.filter(item => item.order_item?.processed_qty > 0)?.length || 0,
    damaged:
      order?.filter(item => item.order_item?.rejected_qty > 0)?.length || 0,
  });
  const [items, setItems] = useState(
    {
      totalDelivered: 0,
      totalRejected: 0,
      total: 0,
    } || {},
  );
  const [boxes, setBoxes] = useState(
    {
      totalDelivered: 0,
      totalRejected: 0,
      total: 0,
    } || {},
  );
  useEffect(() => {
    const boxes = {
      totalDelivered:
        order?.filter(item => item.box?.processed_qty > 0)?.length || 0,
      totalRejected:
        order?.filter(item => item.box?.rejected_qty > 0)?.length || 0,
      total: order?.filter(item => item.box?.code)?.length || 0,
    };

    setBoxes(boxes);
    const items = {
      totalDelivered:
        order?.filter(item => item.order_item?.processed_qty > 0)?.length || 0,
      totalRejected:
        order?.filter(item => item.order_item?.rejected_qty > 0)?.length || 0,
      total: order?.filter(item => item.order_item?.code)?.length || 0,
    };
    setItems(items);
  }, [order]);
  const handleIncrement = useCallback(
    (type, category) => {
      console.log('🚀 > ManualEntry > type, category:', type, category);
      if (
        category === 'boxed' &&
        boxes.total !== boxedItem.delivered + boxedItem.damaged
      ) {
        setBoxItem(prev => ({
          ...prev,
          [type]: prev[`${type}`] + 1,
        }));
      } else if (
        category === 'nonBoxed' &&
        items.total !== nonBoxItem.delivered + nonBoxItem.damaged
      ) {
        setNonBoxItem(prev => ({
          ...prev,
          [type]: prev[`${type}`] + 1,
        }));
      }
    },
    [
      boxedItem.damaged,
      boxedItem.delivered,
      boxes.total,
      items.total,
      nonBoxItem.damaged,
      nonBoxItem.delivered,
    ],
  );
  const handleDecrement = useCallback((type, category) => {
    if (category === 'boxed') {
      setBoxItem(prev => ({
        ...prev,
        [type]: prev[`${type}`] > 0 ? prev[`${type}`] - 1 : 0,
      }));
    } else if (category === 'nonBoxed') {
      setNonBoxItem(prev => ({
        ...prev,
        [type]: prev[`${type}`] > 0 ? prev[`${type}`] - 1 : 0,
      }));
    }
  }, []);
  const onSave = useCallback(() => {
    const manualEntry = {
      is_manual: true,
      total_items: items.total,
      total_boxes: boxes.total,
      delivered_boxes: boxedItem.delivered,
      missing_boxes: boxedItem.damaged,
      delivered_items: nonBoxItem.delivered,
      missing_items: nonBoxItem.damaged,
    };
    submitHandler(manualEntry);
    onCancel();
  }, [
    boxedItem.damaged,
    boxedItem.delivered,
    boxes.total,
    items.total,
    nonBoxItem.damaged,
    nonBoxItem.delivered,
    onCancel,
    submitHandler,
  ]);

  return (
    <Fragment>
      <div className='gap-3' style={{ height: '306px' }}>
        <div className='gap-1 ' style={{ width: '328px', height: '46px' }}>
          <header
            className=' fs-3 fw-400 mt-2'
            style={{
              lineHeight: '24px',
              width: '328px',
              height: '28px',
            }}
          >
            Manual entry for delivery
          </header>
          <p
            className='fw-400'
            style={{
              fontSize: '12px',
              lineHeight: '14px',
            }}
          >
            You can enter number of boxes delivered manually
          </p>
        </div>
        <p
          className='fs-6  fw-400 py4'
          style={{ width: '328px', height: '28px' }}
        >
          Boxed Items ({boxes.total})
        </p>
        <div
          className='first-wrapper d-flex gap-3 '
          style={{ width: '328px', height: '70px' }}
        >
          <div
            className='first-container gap-3 flex-row'
            style={{ width: '156px', height: '70px' }}
          >
            <div
              className='d-flex flex-row  text-center'
              style={{ width: '156px', height: '40px' }}
            >
              <div
                className='minus fs-1 cursor-pointer d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleDecrement('delivered', 'boxed')}
              >
                <MinusCircleIcon color='#006EED' />
              </div>
              <input
                type='text'
                className='fs-2 fw-400 pt-1 border-0 outline-0 text-center custom-input'
                style={{
                  width: '76px',
                  height: '40px',
                }}
                value={boxedItem.delivered}
                onChange={e => {
                  const value = e.target.value.replace(/^0+(?=\d)/, '');
                  setBoxItem(prev => ({
                    ...prev,
                    delivered: value === '' ? 0 : parseInt(value, 10),
                  }));
                }}
              />
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleIncrement('delivered', 'boxed')}
              >
                <PlusCircleIcon color='#006EED' />
              </div>
            </div>
            <div
              className='mt-2 d-flex flex-row gap-2 fs-5 px2 '
              style={{
                width: '84px',
                height: '14px',
                paddingLeft: '2.2rem',
                paddingRight: '1.7rem',
              }}
            >
              <FontAwesomeIcon
                className='fw-900 fs-4  '
                icon={faCircleCheck}
                size='xs'
                style={{
                  color: '#008A50',
                  width: '12px',
                  height: '12px',
                }}
              />
              <p
                className='fw-400 d-flex justify-content-center align-items-center'
                style={{ fontSize: '12px', height: '14px' }}
              >
                DELIVERED{' '}
              </p>
            </div>
          </div>
          <div
            className='second-container gap-3 flex-row'
            style={{ width: '156px', height: '70px' }}
          >
            <div
              className='d-flex flex-row text-center'
              style={{ width: '156px', height: '40px' }}
            >
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleDecrement('damaged', 'boxed')}
              >
                <MinusCircleIcon color='#006EED' />
              </div>
              <input
                type='text'
                className='fs-2 fw-400 pt-1 border-0 outline-0 text-center custom-input'
                style={{
                  width: '76px',
                  height: '40px',
                }}
                value={boxedItem.damaged}
                onChange={e => {
                  const value = e.target.value.replace(/^0+(?=\d)/, '');
                  setBoxItem(prev => ({
                    ...prev,
                    damaged: value === '' ? 0 : parseInt(value, 10),
                  }));
                }}
              />
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleIncrement('damaged', 'boxed')}
              >
                <PlusCircleIcon color='#006EED' />
              </div>
            </div>
            <div
              className='mt-2 d-flex flex-row gap-2 fs-5 text-center'
              style={{
                width: '84px',
                height: '14px',
                paddingLeft: '2.2rem',
                paddingRight: '1.7rem',
              }}
            >
              <FontAwesomeIcon
                className='fw-900 fs-4'
                icon={faCircleExclamation}
                size='xs'
                style={{ color: '#ca0c0c', width: '12px', height: '12px' }}
              />
              <p
                className='fw-400 d-flex justify-content-center align-items-center'
                style={{ fontSize: '12px', height: '14px' }}
              >
                DAMAGED
              </p>
            </div>
          </div>
        </div>
        <p
          className='fs-6  fw-400 py4'
          style={{ width: '328px', height: '28px' }}
        >
          Non-Boxed Items ({items.total})
        </p>
        <div
          className='second-wrapper d-flex gap-3'
          style={{ width: '328px', height: '70px' }}
        >
          <div
            className='first-container gap-3 flex-row'
            style={{ width: '156px', height: '70px' }}
          >
            <div
              className='d-flex flex-row text-center'
              style={{ width: '156px', height: '40px' }}
            >
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleDecrement('delivered', 'nonBoxed')}
              >
                <MinusCircleIcon color='#006EED' />
              </div>
              <input
                type='text'
                className='fs-2 fw-400 pt-1 border-0 outline-0 text-center custom-input'
                style={{
                  width: '76px',
                  height: '40px',
                }}
                value={nonBoxItem.delivered}
                onChange={e => {
                  const value = e.target.value.replace(/^0+(?=\d)/, '');
                  setNonBoxItem(prev => ({
                    ...prev,
                    delivered: value === '' ? 0 : parseInt(value, 10),
                  }));
                }}
              />
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleIncrement('delivered', 'nonBoxed')}
              >
                <PlusCircleIcon color='#006EED' />
              </div>
            </div>
            <div
              className='mt-2 d-flex flex-row gap-2 fs-5'
              style={{
                width: '84px',
                height: '14px',
                paddingLeft: '2.2rem',
                paddingRight: '1.7rem',
              }}
            >
              <FontAwesomeIcon
                className='fw-900 fs-4'
                icon={faCircleCheck}
                size='xs'
                style={{
                  color: '#008A50',
                  width: '12px',
                  height: '12px',
                }}
              />
              <p
                className='fw-400 d-flex justify-content-center align-items-center'
                style={{ fontSize: '12px', height: '14px' }}
              >
                DELIVERED{' '}
              </p>
            </div>
          </div>
          <div
            className='second-container gap-3 flex-row'
            style={{ width: '156px', height: '70px' }}
          >
            <div
              className='d-flex  text-center'
              style={{ width: '156px', height: '40px' }}
            >
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleDecrement('damaged', 'nonBoxed')}
              >
                <MinusCircleIcon color='#006EED' />
              </div>
              <input
                type='text'
                className='fs-3 fw-400  border-0 outline-0  text-center custom-input'
                style={{
                  width: '76px',
                  height: '40px',
                }}
                value={nonBoxItem.damaged}
                onChange={e => {
                  const value = e.target.value.replace(/^0+(?=\d)/, '');
                  setNonBoxItem(prev => ({
                    ...prev,
                    damaged: value === '' ? 0 : parseInt(value, 10),
                  }));
                }}
              />
              <div
                className='minus fs-1 d-flex justify-content-center align-items-center'
                style={{ width: '40px', height: '40px' }}
                onClick={() => handleIncrement('damaged', 'nonBoxed')}
              >
                <PlusCircleIcon color='#006EED' style={{ fontSize: '24px' }} />
              </div>
            </div>
            <div
              className='mt-2 d-flex flex-row gap-2 fs-5 text-center'
              style={{
                width: '84px',
                height: '14px',
                paddingLeft: '2.2rem',
                paddingRight: '1.7rem',
              }}
            >
              <FontAwesomeIcon
                className='fw-900 fs-4'
                icon={faCircleExclamation}
                size='xs'
                style={{ color: '#ca0c0c', width: '12px', height: '12px' }}
              />
              <p
                className='fw-400 d-flex justify-content-center align-items-center pl-1'
                style={{ fontSize: '12px', height: '14px' }}
              >
                DAMAGED
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='d-flex position-fixed bottom-0 gap-2 bg-white'
        style={{ height: '70px' }}
      >
        <button
          className='naadi-back-btn'
          onClick={onCancel}
          style={{ width: '160px', height: '46px' }}
        >
          Cancel
        </button>
        <button
          className='naadi-save-btn '
          style={{ width: '160px', height: '46px' }}
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </Fragment>
  );
};
export default ManualEntry;
