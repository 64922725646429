import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faCheck } from '@fortawesome/free-solid-svg-icons';
const SwipeCTA = ({
  total_processed,
  total_items,
  setEntityModal,
  setBottomDrawerModal,
}) => {
  const [swipeStatus, setSwipeStatus] = useState('idle');
  const [progress, setProgress] = useState(0);

  const handleSwipeStart = useCallback(() => {
    if (swipeStatus !== 'complete') {
      setSwipeStatus('swiping');
    }
  }, [swipeStatus]);

  const handleSwipeMove = useCallback(
    event => {
      if (swipeStatus === 'complete') return;

      const swipeAreaWidth = event.currentTarget.offsetWidth;
      const touchX = event.touches[0].clientX - event.currentTarget.offsetLeft;

      const newProgress = Math.min(
        Math.max((touchX / swipeAreaWidth) * 100, 0),
        100,
      );
      setProgress(newProgress);

      if (newProgress === 100) {
        setSwipeStatus('complete');
      }
    },
    [swipeStatus],
  );

  const handleSwipeEnd = useCallback(() => {
    if (swipeStatus === 'complete' && progress === 100) {
      if (total_processed === total_items) {
        setEntityModal('uploadImagesToComplete');
      } else {
        setEntityModal('itemsAreNotDelivered');
      }
      setBottomDrawerModal(true);
      setProgress(0);
      setSwipeStatus('idle');
    } else {
      setSwipeStatus('idle');
      setProgress(0);
    }
  }, [
    swipeStatus,
    progress,
    total_processed,
    total_items,
    setEntityModal,
    setBottomDrawerModal,
  ]);

  return (
    <div className='swipe-container'>
      <div
        className={`swipe-track ${
          swipeStatus === 'complete' ? 'completed' : ''
        }`}
        onTouchStart={handleSwipeStart}
        onTouchMove={handleSwipeMove}
        onTouchEnd={handleSwipeEnd}
      >
        <div className='swipe-progress' style={{ width: `${progress}%` }}></div>

        {swipeStatus === 'complete' ? (
          <div className='check-icon'>
            <FontAwesomeIcon icon={faCheck} size='lg' />
          </div>
        ) : (
          <div
            className='swipe-handle'
            style={{ width: `${Math.max(progress, 19)}%` }}
          >
            <FontAwesomeIcon icon={faAnglesRight} size='lg' />
          </div>
        )}

        <div className='naadi-text swipe-text'>
          {swipeStatus === 'complete' ? '' : 'Swipe to Complete Order'}
        </div>
      </div>
    </div>
  );
};

export default SwipeCTA;
